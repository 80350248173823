.features-container {
  flex-direction: column;
  background-color: var(--color-bg-yellow);
  padding-bottom: 150px;
  text-align: center;
  color: var(--color-text-light);
  width: 100%;
}

.key-features-title{
 display: flex;
 font-size: 40px;
 margin-bottom: 60px;
 font-weight: 700;
 font-family: var(--font-family);
 justify-content: flex-start; /* Aligns content to the left */
 padding-left: 120px;
 position: relative;  /* Required for absolute positioning of child elements */
}

.key-features-title::after {
  content: "";  /* Necessary for the pseudo-element to be visible */
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;  /* Adjust this to move the border up or down */
  height: 2px;  /* This is the thickness of your border */
  background-color: black;  /* This is the color of your border */
}
.features {
  display: flex;
  width: 100%; /* Takes up full width */
  justify-content: space-around; /* Distributes the features evenly */
}

.feature-one, .feature-two, .feature-three {
    flex: 1;
    padding-top: 20px;
}
.feature img{
width: 90%;
height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.feature img:hover{
   transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.features-container h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.feature h3 {
  font-size: 24px;
  margin-bottom: 10px;
  padding-top:20px;
}

.feature p {
  font-size: 16px;
  color: #777;
}

/* Your existing CSS remains unchanged... */
/* Your existing styles remain unchanged... */
@media (max-width: 900px) {
  .key-features-title {
   display: flex;  /* Ensure it's a flex container */
   flex-direction: row;
   font-size: 35px;
   font-weight: 700;
   font-family: var(--font-family);
   justify-content: flex-start; /* Aligns content to the left */
   align-items: center; /* Vertically center align items if needed */
   padding-left: 20px;  /* Add some padding to ensure it doesn’t touch the screen edge */
  }

  .key-features-title::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    height: 2px;
    background-color: #000000;
  }
}


/* Media Query for Mobile */
@media (max-width: 900px) {
  .features {
    flex-direction: column;
    align-items: center; /* To center the feature blocks */
  }

  .feature-one, .feature-two, .feature-three {
    max-width: 90%; /* You can adjust this percentage based on preference */
    margin-bottom: 20px; /* Space between stacked features */
  }
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .key-features-title {
   display: flex;  /* Ensure it's a flex container */
   flex-direction: row;
   font-size: 35px;
   font-weight: 700;
   font-family: var(--font-family);
   justify-content: flex-start; /* Aligns content to the left */
   align-items: center; /* Vertically center align items if needed */
   padding-left: 20px;  /* Add some padding to ensure it doesn’t touch the screen edge */
  }
    .features-container{
    padding-bottom: 100px;
    }
  .key-features-title::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    height: 2px;
    background-color: #000000;
  }
}


/* Media Query for Mobile */
@media (max-width: 768px) {
  .features {
    flex-direction: column;
    align-items: center; /* To center the feature blocks */
  }

  .feature-one, .feature-two, .feature-three {
    max-width: 90%; /* You can adjust this percentage based on preference */
    margin-bottom: 20px; /* Space between stacked features */
  }
}
