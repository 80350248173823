/* --- Container Layout --- */
.pyramid-story-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0rem 5rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

/* --- Dividing Line --- */
.divider-line {
  width: 90%;
  height: 2px;
  background-color: #e5e5e5;
  margin-bottom: 2rem;
}
.main-title-bg{
    width: 100%;
    display: flex;
    height: 10.5rem;
    background: var(--color-bg-darkGrey);
    align-content: center;
    justify-content: center;
    align-items: center;
    color: var(--color-bg-offWhite);
    flex-direction: column;
}
/* --- Main Title --- */
.main-title {
  font-size: 3rem;
  font-weight: 700;
  color: #111827;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--color-bg-yellow);
}

.subtitle{
    color: var(--color-bg-offWhite);
    padding-bottom: 1rem;
}
/* --- Content Wrapper --- */
.pyramid-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 3rem;
  padding: 3rem;
  justify-content: space-between;
}

/* --- Left Panel: Title, Description, Buttons --- */
.pyramid-left-panel {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin: auto 0;
  background-color: #f9f9f9;
  padding: 2.5rem;
  transition: transform 0.3s ease;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.description-box {
  margin-bottom: 2rem;
}

.description-box h2 {
  margin-bottom: 0.8rem;
  font-size: 1.9rem;
  font-weight: 600;
  color: #111827;
}

.accent-line {
  width: 60px;
  height: 3px;
  background-color: var(--color-bg-green);
  margin-bottom: 1.2rem;
  border-radius: 2px;
}

.description-box p {
  font-size: 1.05rem;
  line-height: 1.7;
  color: #4b5563;
  max-width: 95%;
}

/* --- Nav Controls Container --- */
.nav-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* --- Nav Buttons --- */
.nav-buttons {
  display: flex;
  gap: 0.7rem;
}

.nav-buttons button {
  padding: 0.7rem 1.2rem;
  cursor: pointer;
  border: 2px solid #111827;
  background-color: transparent;
  color: #111827;
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.nav-buttons button:hover {
  background-color: #111827;
  color: #ffffff;
  transform: translateY(-2px);
}

.nav-buttons button.active {
  background-color: #111827;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* --- Consulting Button --- */
.consulting-button {
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  border: 2px solid var(--color-bg-green);
  background-color: transparent;
  color: var(--color-bg-green);
  font-weight: 600;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 1rem;
  margin-top: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.consulting-button:hover {
  background-color: var(--color-bg-green);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

/* --- Right Panel: consistent space to avoid screen jump --- */
.pyramid-right-panel {
  flex: 1;
  min-width: 300px;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pyramid-layer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.07);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pyramid-layer:hover {
  transform: scale(1.02);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.pyramid-image {
  max-width: 450px;
  max-height: 100%;
  object-fit: contain;
  display: block;
  transition: transform 0.5s ease;
}

.pyramid-image:hover {
  transform: scale(1.05);
}

/* --- Responsive Tweaks --- */
@media (max-width: 1024px) {
  .pyramid-content-wrapper {
    gap: 2rem;
  }

  .main-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .pyramid-story-container {
    padding: 2rem 1.5rem;
  }

  .main-title {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }

  .pyramid-left-panel {
    min-width: 100%;
    margin-bottom: 2rem;
    padding: 2rem;
  }

  .pyramid-right-panel {
    min-width: 100%;
    min-height: 350px;
  }

  .description-box h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .divider-line {
    width: 90%;
  }

  .main-title {
    font-size: 1.8rem;
  }

  .description-box h2 {
    font-size: 1.4rem;
  }

  .description-box p {
    font-size: 0.95rem;
  }

  .pyramid-right-panel {
    min-height: 250px;
  }

  .pyramid-image {
    max-width: 250px;
  }

  .pyramid-left-panel {
    padding: 1.5rem;
  }
}