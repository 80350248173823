.slider-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
    font-size: 18px;
  padding-left: 10px;
  padding-top: 5px;
}

.slider {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;

}

.slider.on {
  background-color: #4CAF50; /* Green background when on */
}

.slider.off {
  background-color: #ccc; /* Gray background when off */
}

.slider-circle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.slider.on .slider-circle {
  transform: translateX(20px);
}
.info-text {
  position: relative;
  cursor: pointer; /* Ensures cursor is pointer over ON/OFF text */
}
.tooltip-target {
  position: relative;
  cursor: default; /* Keeps the cursor default */
}

.tooltip {
  position: absolute;
  top: -5px; /* Adjust based on your layout */
  left: 50%;
  transform: translate(-18%, 18%); /* Center above the toggle and move it up */
  width: 400px; /* Square shape: equal width and height */
  height: 300px; /* Square shape: equal width and height */
  background-color: #333;
  color: white;
  padding: 0px 8px 0px 8px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px; /* Adjust for desired corner roundness */
  z-index: 100;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds shadow for better visibility */
}
.tooltip p{
    font-size: 15px;
    text-align: left;
    padding-bottom: 10px;
    color: white;

}
.underlined-text {
  text-decoration: underline;
}
@media (max-width: 930px) {
  .tooltip {
    width: 300px;
    height: 400px;
    transform: translate(-25%, 10%); /* Center above the toggle and move it up */

  }
}
@media (max-width: 700px) {
  .tooltip {
    width: 250px;
    height: 500px;
    transform: translate(-42%, 10%); /* Center above the toggle and move it up */

  }
}