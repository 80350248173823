.chat-type-container {
  position: relative;
  width: 30vh;
  font-family: var(--font-family);
  margin-left: auto;
  margin-right: auto;
}

.chat-type-header {
  position: relative;
  width: 30vh;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
}

.arrow {
  display: inline-block;
  position: absolute; /* Absolute positioning within the header */
    right: 10px; /* Adjust as needed to position from the right edge */
    top: 50%; /* Center it vertically */
    transform: translateY(-50%) rotate(45deg); /* Adjust vertical centering and rotation */
  width: 10px;
  height: 10px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(45deg);
  margin-right: 5px;
  margin-left: 5px;
    /* Adjust margin, size and color as needed */
}

.arrow.open {
  transform: translateY(-50%) rotate(-135deg);
    /* Keep the vertical alignment and just rotate differently */
}

.dropdown-list {
  position: absolute;
  width: 40vh;
  top: 55px;
  left: -5vh;
  right: 0;
  z-index: 10;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #fff;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  user-select: none;
}

.dropdown-item:hover {
  background-color: #f6f6f6;
}
.option-label {

    /* Additional styles for the label */
}

.option-description {
    font-size: 15px;; /* Example style */
    color: #666; /* Example style */
    text-align: left; /* Example style */
    /* Additional styles for the description */
}