:root {
  --consulting-color-bg-yellow: #f0dd5f;
  --consulting-color-bg-green: #156e12;
  --consulting-color-bg-black: #071a14;
  --consulting-color-bg-offWhite: #e9eae0;
  --consulting-color-bg-darkGrey: #0f2922;
}

/* Base Styles */
.consulting-container {
  min-height: 100vh;
  font-family: var(--font-family);
  color: var(--consulting-color-bg-black);
  position: relative;
}

.consulting-inner-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

/* Section Styles */
.consulting-section-header {
  text-align: center;
  margin-bottom: 60px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-section-header .consulting-overline {
  display: inline-block;
  color: var(--consulting-color-bg-green);
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 16px;
  position: relative;
}

.consulting-section-header .consulting-overline:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 40px;
  background-color: var(--consulting-color-bg-yellow);
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.consulting-section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 20px;
}

.consulting-section-subtitle {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--consulting-color-bg-darkGrey);
  max-width: 700px;
  margin: 0 auto;
}

/* Animation for sections */
.consulting-animate-section {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.consulting-animate-section.consulting-animate-in {
  opacity: 1;
  transform: translateY(0);
}

/* Hero Section */
.consulting-hero-section {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(7, 26, 20, 1); /* Slightly transparent to show network */
}

.consulting-hero-content {
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
  width: 100%;
  padding: 80px 20px;
}

.consulting-hero-text {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.consulting-hero-section h1 {
  font-size: 4rem;
  font-weight: 700;
  color: var(--consulting-color-bg-yellow);
  margin-bottom: 0;
  line-height: 1.1;
}

.consulting-hero-section h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--consulting-color-bg-green);
  margin-top: 8px;
  margin-bottom: 24px;
}

.consulting-hero-subtitle {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 40px;
  color: var(--consulting-color-bg-offWhite);
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.consulting-cta-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background-color: var(--consulting-color-bg-green);
  color: var(--consulting-color-bg-offWhite);
  border-radius: 25px;
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.consulting-cta-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--consulting-color-bg-yellow);
  transition: width 0.3s ease;
  z-index: -1;
}

.consulting-cta-button:hover {
  color: var(--consulting-color-bg-black);
}

.consulting-cta-button:hover:before {
  width: 100%;
}

.consulting-arrow-icon {
  transition: transform 0.3s ease;
}

.consulting-cta-button:hover .consulting-arrow-icon {
  transform: translateX(5px);
  color: var(--consulting-color-bg-black);
}

/* Advantage Section */
.consulting-advantage-section {
  padding: 100px 0;
  background-color: var(--consulting-color-bg-offWhite);
}

.consulting-advantage-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 60px;
}

.consulting-advantage-card {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 30px;
  background: white;
  border-left: 4px solid var(--consulting-color-bg-green);
  box-shadow: 0 5px 30px rgba(7, 26, 20, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-advantage-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(7, 26, 20, 0.12);
}

.consulting-advantage-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: rgba(21, 110, 18, 0.1);
  border-radius: 50%;
  flex-shrink: 0;
}

.consulting-advantage-icon svg {
  width: 30px;
  height: 30px;
  color: var(--consulting-color-bg-green);
}

.consulting-advantage-content h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 12px;
}

.consulting-advantage-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--consulting-color-bg-darkGrey);
}

/* Services Section with Tabs */
.consulting-services-section {
  padding: 100px 0;
  background: linear-gradient(180deg, var(--consulting-color-bg-offWhite) 0%, white 100%);
}

.consulting-services-tabs {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.consulting-tabs-navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-bottom: 0;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-tab-button {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 20px;
  background-color: rgba(233, 234, 224, 0.95); /* More opaque background */
  color: var(--consulting-color-bg-darkGrey);
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-tab-button .consulting-tab-icon {
  width: 24px;
  height: 24px;
  color: var(--consulting-color-bg-green);
  transition: all 0.3s ease;
}

.consulting-tab-button:hover, .consulting-tab-button.consulting-active {
  background-color: var(--consulting-color-bg-green);
  color: white;
}

.consulting-tab-button:hover .consulting-tab-icon, .consulting-tab-button.consulting-active .consulting-tab-icon {
  color: var(--consulting-color-bg-yellow);
}

.consulting-tab-content {
  padding: 40px;
  background-color: white;
  border: 1px solid rgba(21, 110, 18, 0.1);
  box-shadow: 0 5px 30px rgba(7, 26, 20, 0.05);
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-tab-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.consulting-tab-content-icon {
  width: 32px;
  height: 32px;
  color: var(--consulting-color-bg-green);
}

.consulting-tab-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--consulting-color-bg-darkGrey);
  margin: 0;
}

.consulting-tab-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 24px;
}

.consulting-tab-details {
  font-size: 1rem;
  line-height: 1.7;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 32px;
}

.consulting-tab-benefits h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 16px;
}

.consulting-tab-benefits ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
}

.consulting-tab-benefits li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.consulting-benefit-icon {
  width: 20px;
  height: 20px;
  color: var(--consulting-color-bg-green);
  flex-shrink: 0;
  margin-top: 3px;
}

/* Approach Section with Timeline */
.consulting-approach-section {
  padding: 100px 0;
  background-color: rgba(15, 41, 34, 1); /* Slightly transparent to show network */
}

.consulting-approach-section .consulting-section-header h2 {
  color: var(--consulting-color-bg-yellow);
}

.consulting-approach-section .consulting-section-header .consulting-overline {
  color: var(--consulting-color-bg-yellow);
}

.consulting-approach-section .consulting-section-subtitle {
  color: var(--consulting-color-bg-offWhite);
}

.consulting-timeline {
  position: relative;
  max-width: 900px;
  margin: 60px auto 0;
}

.consulting-timeline:before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: var(--consulting-color-bg-green);
  top: 0;
  bottom: 0;
  left: 18px;
  margin-left: -2px;
}

.consulting-timeline-item {
  position: relative;
  margin-bottom: 50px;
  padding-left: 60px;
}

.consulting-timeline-item:last-child {
  margin-bottom: 0;
}

.consulting-timeline-number {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  background-color: var(--consulting-color-bg-yellow);
  color: var(--consulting-color-bg-black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 700;
  z-index: 11; /* Above the timeline line */
}

.consulting-timeline-content {
  background-color: rgba(15, 41, 34, 0.85); /* Darker background with opacity */
  padding: 30px;
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-timeline-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--consulting-color-bg-yellow);
  margin-bottom: 16px;
}

.consulting-timeline-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--consulting-color-bg-offWhite);
  margin-bottom: 20px;
}

.consulting-timeline-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.consulting-timeline-features li {
  position: relative;
  padding-left: 20px;
  font-size: 0.95rem;
  color: rgba(233, 234, 224, 0.8);
}

.consulting-timeline-features li:before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--consulting-color-bg-yellow);
  font-size: 1.2rem;
  line-height: 1;
}

/* Contact Section - COMPLETELY REDESIGNED */
.consulting-contact-section {
  padding: 100px 0;
  background-color: rgba(7, 26, 20, 1); /* Slightly transparent to show network */
  position: relative;
}

/* Change contact section header text to yellow */
.consulting-contact-section .consulting-section-header h2 {
  color: var(--consulting-color-bg-yellow);
}

.consulting-contact-section .consulting-section-header .consulting-section-subtitle {
  color: var(--consulting-color-bg-offWhite);
}

.consulting-contact-section .consulting-section-header .consulting-overline {
  color: var(--consulting-color-bg-yellow);
}

.consulting-contact-section .consulting-section-header .consulting-overline:after {
  background-color: var(--consulting-color-bg-green);
}

.consulting-contact-card {
  max-width: 1100px;
  margin: 40px auto 0;
  background: linear-gradient(135deg, var(--consulting-color-bg-darkGrey) 0%, var(--consulting-color-bg-black) 100%);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10; /* Increased to ensure content is above network background */
}

.consulting-contact-card-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.consulting-contact-info {
  padding: 50px 40px;
  color: var(--consulting-color-bg-offWhite);
}

.consulting-contact-info h3 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--consulting-color-bg-yellow);
  margin-bottom: 20px;
}

.consulting-contact-info p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.consulting-contact-features {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.consulting-contact-feature {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.consulting-feature-icon {
  width: 20px;
  height: 20px;
  color: var(--consulting-color-bg-yellow);
  flex-shrink: 0;
  margin-top: 3px;
}

.consulting-contact-feature p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--consulting-color-bg-offWhite);
  margin: 0;
}

.consulting-contact-form-container {
  background-color: white;
  padding: 50px 40px;
  position: relative;
  z-index: 10; /* Ensure it's above network background */
}

.consulting-form-header {
  margin-bottom: 30px;
  text-align: center;
}

.consulting-form-header h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 10px;
}

.consulting-form-header p {
  font-size: 1rem;
  color: #666;
}

.consulting-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.consulting-form-group {
  margin-bottom: 25px;
}

.consulting-form-group label {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--consulting-color-bg-darkGrey);
  margin-bottom: 8px;
}

.consulting-form-group input,
.consulting-form-group textarea {
  width: 100%;
  padding: 15px;
  background-color: #f8f8f8;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 1rem;
  color: var(--consulting-color-bg-darkGrey);
  transition: all 0.3s ease;
}

.consulting-form-group input:focus,
.consulting-form-group textarea:focus {
  border-color: var(--consulting-color-bg-green);
  outline: none;
  box-shadow: 0 0 0 3px rgba(21, 110, 18, 0.1);
  background-color: white;
}

.consulting-form-group input::placeholder,
.consulting-form-group textarea::placeholder {
  color: #aaa;
}

.consulting-form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.consulting-submit-button {
  width: 100%;
  padding: 16px;
  background-color: var(--consulting-color-bg-green);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.consulting-submit-button:hover {
  background-color: var(--consulting-color-bg-yellow);
  color: var(--consulting-color-bg-black);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.consulting-button-icon {
  transition: transform 0.3s ease;
}

.consulting-submit-button:hover .consulting-button-icon {
  transform: translateX(5px);
}

/* Success Message */
.consulting-success-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--consulting-color-bg-green);
  color: white;
  padding: 15px 25px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: consulting-slideIn 0.5s ease-out;
  z-index: 1000;
}
/* Add these styles to your AIConsulting.css file */

/* Form Notification System - Replaces the old consulting-success-message */
.consulting-form-notification {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 6px;
  padding: 15px;
  animation: consulting-slideIn 0.4s ease-out;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.success-notification {
  background-color: rgba(21, 110, 18, 0.1);
  border-left: 4px solid var(--consulting-color-bg-green);
}

.error-notification {
  background-color: rgba(220, 53, 34, 0.1);
  border-left: 4px solid #dc3545;
}

.consulting-notification-icon {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  flex-shrink: 0;
}

.success-notification .consulting-notification-icon {
  background-color: var(--consulting-color-bg-green);
  color: white;
}

.error-notification .consulting-notification-icon {
  background-color: #dc3545;
  color: white;
}

.consulting-notification-message {
  flex-grow: 1;
  font-size: 0.95rem;
  line-height: 1.5;
}

.success-notification .consulting-notification-message {
  color: var(--consulting-color-bg-green);
}

.error-notification .consulting-notification-message {
  color: #dc3545;
}

.consulting-notification-close {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s;
}

.consulting-notification-close:hover {
  color: var(--consulting-color-bg-darkGrey);
}

/* Button state for form submission */
.consulting-submit-button.submitting {
  background-color: rgba(21, 110, 18, 0.7);
  transform: none;
  cursor: not-allowed;
  position: relative;
  overflow: hidden;
  box-shadow: none;
}

.consulting-submit-button.submitting:hover {
  background-color: rgba(21, 110, 18, 0.7);
  transform: none;
  box-shadow: none;
}

.consulting-submit-button.submitting::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: consulting-loadingShimmer 1.5s infinite;
}

/* Since we already have consulting-slideIn animation, let's create a similar one for shimmer */
@keyframes consulting-loadingShimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Hide the old success message since we're replacing it with a better system */
.consulting-success-message {
  display: none;
}
@keyframes consulting-slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 992px) {
  .consulting-contact-card-inner {
    grid-template-columns: 1fr;
  }

  .consulting-contact-info {
    padding: 40px 30px;
  }

  .consulting-contact-form-container {
    padding: 40px 30px;
  }

  .consulting-hero-section h1 {
    font-size: 3rem;
  }

  .consulting-hero-section h2 {
    font-size: 2rem;
  }

  .consulting-tab-button {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .consulting-section-header h2 {
    font-size: 2rem;
  }

  .consulting-hero-section h1 {
    font-size: 2.5rem;
  }

  .consulting-hero-section h2 {
    font-size: 1.5rem;
  }

  .consulting-hero-subtitle {
    font-size: 1rem;
  }

  .consulting-tabs-navigation {
    flex-direction: column;
  }

  .consulting-tab-button {
    flex-direction: row;
    justify-content: flex-start;
  }

  .consulting-tab-content {
    padding: 20px;
  }

  .consulting-timeline-features {
    grid-template-columns: 1fr;
  }

  .consulting-form-row {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .consulting-contact-info h3 {
    font-size: 1.6rem;
  }

  .consulting-form-header h3 {
    font-size: 1.6rem;
  }
}