.business-size-section {
  padding: 80px 40px;
  padding-top: 40px;
  background: linear-gradient(135deg, #f5f7ff 0%, #fff 100%);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.business-size-section.in-view {
  opacity: 1;
  transform: translateY(0);
}

.size-section-header {
  text-align: center;
  margin-bottom: 60px;
}

.size-section-header h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

.size-section-header p {
  font-size: 1.2rem;
  color: #666;
}

.business-cards-container {
  display: flex;
  gap: 40px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.business-card {
  flex: 1;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.business-card:hover {
  transform: translateY(-10px);
}

.card-content {
  padding: 40px;
}

.size-tag {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.business-card.smb .size-tag {
  background: #e9967a;
  color: white;
}

.business-card.enterprise .size-tag {
  background: #4a90e2;
  color: white;
}

.card-content h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #333;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 40px 0;
}

.feature-business-size {
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.feature-business-size:hover {
  background: #f0f2f5;
}

.feature-business-size h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.feature-business-size p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
}

.learn-more-btn {
  width: 100%;
  padding: 16px 32px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #007bff;
  color: white;
}

.learn-more-btn.enterprise {
  background: white;
  color: #333;
  border: 2px solid #333;
}

.learn-more-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .business-cards-container {
    flex-direction: column;
    max-width: 600px;
  }

  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .business-size-section {
    padding: 60px 20px;
  }

  .size-section-header h2 {
    font-size: 2.5rem;
  }

  .card-content {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .size-section-header h2 {
    font-size: 2rem;
  }

  .card-content h3 {
    font-size: 1.8rem;
  }
}