.iconSection-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 10vh;
    font-family: var(--font-family);
    color: var(--color-bg-darkGrey);
}

.iconSection-top-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    justify-content: space-around;
}
.icon-container-special, .icon-container-normal{
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%;
    justify-content: center;
    padding: 1vh;
    padding-top: 3vh;
    padding-left: 3vh;
    padding-right: 3vh;
    text-align: left;

}
.icon-container-special{
    background-color : var(--color-bg-yellow);
    border-bottom-right-radius: 150px;
    border-bottom-left-radius: 150px;
    padding-left: 5vh;
    height: 300px;
    width: 300px;

}
.icon-icon{
    display: flex;
    height: 25%;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: left;
}
.top-icon{
    width: 50px;
    height: 50px;
    overflow: hidden;
}
.top-icon-reupload{
    width: 50px;
    height: 61px;
}
.top-icon-security{
    width: 61px;
    height: 50px;
}
.bottom-icon{
    width: 80px;
    height: 80px;
    overflow: hidden;
}
.icon-title{
    display: flex;
    height: 20%;


}
.icon-text{
    display: flex;
    height: 55%;
    text-align: left;
}
.icon-text p{
    font-size: 17px;
}
.icon-title h3{
    font-size: 20px;

}
.icon-bottom-container{
    display: flex;
    margin-top: 2vh;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    align-items: center;
    margin-top: 5vh;
    justify-content: space-between;
}
.icon-bottom-container-left{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    align-items: center;
    padding-left: 5vh;
}
.icon-bottom-container-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 100%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: var(--color-bg-offWhite);
    border-bottom-left-radius: 250px;
    border-top-left-radius: 250px;
}
.icon-icon-bottom{
    display: flex;
    height: 20%;
    align-items: left;
    justify-content: left;
}
.icon-title-bottom{
    display: flex;
    height: 15%;

}
.icon-text-bottom{
    display: flex;
    height: 55%;
    width: 50%;
    text-align: left;
}
.icon-text-bottom p{
    font-size: 20px;
}
@media (max-width: 900px) {
 .iconSection-top-container{
 height: 60vh;
 }
 .icon-container-normal, .icon-container-special {
 width: 30%;
 }
 .icon-title{
 height: 60px;
 }
 .icon-text{
 height: auto;
 }
}
/* Medium screens: 2 icons per row */
@media (max-width: 768px) { /* Adjust breakpoint as needed */
    .iconSection-top-container {
        flex-wrap: wrap;
        height: auto;
    }
    .icon-container-normal, .icon-container-special {
        flex-basis: 50%; /* Adjust as needed */
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .icon-container-special{
    padding-bottom: 100px;
    }
    .icon-container-normal{
    padding-bottom: 20px;
    }
    .icon-text{
    align-items: center;
    text-align: center;
    }


    .icon-bottom-container{
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    }

    .icon-bottom-container-left,.icon-bottom-container-right{
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 5vh;
    padding-bottom:8vh;

    }
}

/* Small screens: 2 rows with 1 icon each and 1 row with 2 icons */
@media (max-width: 480px) { /* Adjust breakpoint as needed */
    .icon-container-normal, .icon-container-special {
        flex-basis: 100%; /* First two icons each take full width */
    }
    .icon-container-normal:nth-last-child(-n+2) { /* Adjust for the last two icons */
        flex-basis: 50%;
    }
}