.chatArea {
   margin-bottom: 10vh;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.message-group {
  margin-bottom: 20px;
  width: 65%;
  margin-left: 6.5%;

}

.user-message, .ai-message {
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 80%;
  margin-top: 30px;
}

.user-message {
  background-color: var(--color-bg-purple-lightest);
  text-align: right;
}

.ai-message {
  background-color: var(--color-bg-orange-lightest);
}

.chatArea p, .chatArea li {
  color: black;
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 8px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.chatArea li {
  margin-left: 4vh;
}

.user-text {
  color: #CE93D8;
  font-weight: 600;
}

.helix-text, .sherpa-text {
  color: #F49867;
  font-weight: 600;
}

.source-link, .source-title {
  color: var(--color-text);
  font-family: var(--font-family);
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.source-button {
  background-color: transparent;
  border: none;
  color: #F49867;
  cursor: pointer;
  margin-top: 10px;
  font-family: var(--font-family);
  font-size: 16px;
}

.source-container {
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(244, 152, 103, 0.05);
  border-radius: 5px;
}

.loading-pdf {
  font-style: italic;
  color: var(--color-text);
}

.top-message {
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  color: var(--color-text);
  font-family: var(--font-family);
}

/* Existing modal styles */
.modal-overlay, .modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content, .modal-content-custom {
  position: relative;
  background: none;
  padding: 20px;
  width: 50%;
  min-height: 30vh;
  border-radius: 8px;
}

.modal-content .close-modal-btn,
.modal-content-custom .close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-overlay-custom {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.modal-content-custom {
  height: auto;
}