.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  font-family: var(--font-family);
  /*background-image: linear-gradient(to right bottom, #040c18, #051021, #04142b, #011735, #00193f);*/
}

.landing-header {
  margin: 0;
  padding: 2rem;

  color: var(--color-text-light);
  width: 100%;
  text-align: center;
}

.landing-content {
  flex: 1;
  color: var(--color-text-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 6rem; /* Use the same padding scheme as in App.css */
}

.landing-footer {
  padding: 1rem;
  color: var(--color-text-light);
  width: 100%;
  background-color: #AE67FA;
  text-align: center;
}

/* Animation as per global styles */
/*.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
*/
.landing-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-left-content, .footer-right-content{
    display: flex;
}
.footer-left-content{
    padding-bottom: 30px;
}