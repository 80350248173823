
.footer-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    height: auto;
    background-color: var(--color-bg-darkGrey);
    font-family: var(--font-family);
     padding-top: 4vh;
     align-items: center;
     color: var(--color-bg-offWhite);
}

.footer-upper-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 80%;
    padding: 2vh;
    padding-left: 5vh;

}
.social-icons {
    filter: invert(100%);
}

.footer-helix-explanation{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.footer-helix-explanation-paragraph{
    width: 50%;
}
.footer-helix-explanation-text h4{
    font-size: 17px;
    padding-bottom: 10px;

}
.footer-helix-explanation-paragraph p{
    font-size: 13px;
    padding-bottom: 10px;
}
.footer-helix-explanation-try-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

}
.try-helix-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 45%;
    height: 35px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 13px;
    border-radius: 20px;
}


.footer-link-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
}
.footer-link-subsections{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.footer-link-subsections h4{
    font-size: 17px;
    padding-bottom: 10px;
}
.footer-link-subsections p{
    font-size: 13px;
    padding-bottom: 10px;
}
.footer-lower-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 20%;
    padding-left: 5vh;
    padding-top: 5vh;
    padding-bottom: 20px;
    border-top: 2px solid #494949;
}
.footer-lower-socials{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 23%;
    height:auto;
    padding-right: 5vw;

}
.social-icons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 20px;
    height:auto;
    margin-right: 5px;
}
.footer-lower-contact p{
font-size: 13px;
}

@media (max-width: 800px){

.footer-lower-content{
width: 100%;

}
.footer-helix-explanation{
width: 40%;
}
.try-helix-button{
width : 55%;
}
}
@media (max-width: 580px){

.footer-upper-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 80%;
    padding: 2vh;
    padding-left: 5vh;
}
.footer-helix-explanation{
padding-bottom: 40px;

}
.try-helix-button{
width : 65%;
}
.footer-helix-explanation-text{
    padding-left: 1vw;
    }

.footer-link-section{
flex-direction: column;
padding-bottom: 20px;

}
.footer-link-subsections{
    width: 100%;
    padding-bottom: 30px;
}
.footer-lower-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 20%;
    padding-left: 5vh;
    padding-top: 5vh;
    padding-bottom: 20px;
    border-top: 2px solid #494949;
}
.footer-lower-socials{
    padding-bottom: 40px;
    padding-left: 3vh;
    width: 100%;
}
.social-icons{
margin-right: 15px;
}
.footer-lower-contact{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
}
@media (max-width: 440px){
.try-helix-button{
width : 75%;
}
}
@media (max-width: 390px){
.try-helix-button{
width : 85%;
}
}
@media (max-width: 350px){
.try-helix-button{
width : 100%;
}
}