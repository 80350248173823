.Blog-list-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: var(--font-family);
    color: var(--color-bg-darkGrey);
    height:auto;
    padding-bottom: 200px;
}

.blog-list-title-color-bg{

    display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: left;
  text-align: left;
  padding-top: 5vh; /* Adjust top and bottom padding as needed */
  padding-bottom: 1vh;
  padding-right: 2vh;
}
.blog-list-title-color-bg::after {
  content: '';
  display: block;
  width: 100%;
  margin-bottom: 10vh;
  height: 1px; /* Height of the separator line */
  background-color: black;
  margin-top: 4vh; /* Space after the title */
}
/* This will style the title section */
.blog-list-title-section {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-offWhite);
  width: 30%;
  justify-content: center;
  align-items: left;
  text-align: left;
  padding-top: 8vh; /* Adjust top and bottom padding as needed */
  padding-bottom: 10vh;
  padding-right: 2vh;
  padding-left: 2vh;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.blog-list-title-section h1{
    font-size: 38px;
    font-weight: 600;
    text-align: left;
    padding-left: 4.5%;
}
.blog-list {
    list-style: none; /* Removes the default list styling */
    width: 100%; /* Sets the width to 100% */
    padding: 0; /* Removes default padding */
}
.blog-list li {
    font-size: 20px; /* Larger font size */
    margin-bottom: 20px; /* Spacing between list items */
    padding-left: 2vh;
    padding-top: 1vh;
color: var(--color-bg-darkGrey);
}

.blog-list a {
    text-decoration: none; /* Removes the underline from links */
color: var(--color-bg-darkGrey);
}

.blog-list a:hover {
    text-decoration: underline; /* Underline on hover for better user experience */
}
.blog-list-item {
    display: flex; /* Use flexbox for layout */
    align-items: left; /* Align items vertically */
    justify-content: flex-start; /* Space out the list item contents */
    margin-bottom: 20px; /* Spacing between list items */
    font-size: 16px; /* Adjust as needed */
}

.blog-date {
   display: flex;
   width: 30%;
}

.blog-title {
    display: flex;
    text-decoration: none;
    text-align: left;
    font-weight: bold; /* Optional: makes the title bold */
    color: var(--color-text); /* Text color for the title */
    font-size: 20px; /* Larger font size */
    color: var(--color-bg-darkGrey);
}

.blog-title:hover {
    text-decoration: underline;
}
@media (max-width: 480px) {
.blog-list-item {
    display: flex; /* Use flexbox for layout */
    flex-direction: column;
    align-items: left; /* Align items vertically */
    justify-content: flex-start; /* Space out the list item contents */
    margin-bottom: 20px; /* Spacing between list items */
    font-size: 16px; /* Adjust as needed */
}

.blog-date {
   display: flex;
   width: 100%;
}

.blog-title {
    display: flex;
    width: 100%;
    padding-top: 1vh;
    text-decoration: none;
    text-align: left;
    font-weight: bold; /* Optional: makes the title bold */
    font-size: 20px; /* Larger font size */
color: var(--color-bg-darkGrey);

}
}