/* CaseStudyPreview.css */

.case-studies-section {
  width: 100%;
}

.hero-section {
  width: 100%;
  background-color: #111827;
  padding: 4rem 0;
}

.hero-content-caseStudies {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

.hero-title-caseStudies {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-bg-yellow);
  margin-bottom: 1rem;
  animation: fadeIn 0.5s ease-out;
  background: var(--color-bg-darkGrey);
}
.case-studies-top-bar{
  background: var(--color-bg-darkGrey);
  padding-bottom: 2rem;

}
.hero-description-caseStudies {
  font-size: 1.25rem;
  color: var(--color-bg-offWhite);
  max-width: 48rem;
  margin: 0 auto;
}

.content-wrapper {
  max-width: 80rem;
  margin: 0 auto;
  padding: 3rem 1rem;
}

.case-studies-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
}

@media (min-width: 768px) {
  .case-studies-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .case-studies-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.case-study-card {
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
}

.case-study-card.hovered {
  transform: translateY(-0.5rem);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  height: 12rem;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.case-study-card.hovered .image-overlay {
  opacity: 1;
}

.study-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.case-study-card.hovered .study-image {
  transform: scale(1.1);
}

.card-content {
  padding: 1.5rem;
  position: relative;
}

.action-badge {
  position: absolute;
  top: -1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  background-color: #fbbf24;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.case-study-card.hovered .action-badge {
  transform: rotate(12deg);
}

.badge-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #111827;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.75rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-description {
  color: #4b5563;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  display: flex;
  align-items: center;
  color: #d97706;
  font-weight: 500;
  transition: color 0.3s ease;
}

.case-study-card.hovered .read-more {
  color: #b45309;
}

.read-more-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.case-study-card.hovered .read-more-icon {
  transform: translateX(0.25rem);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}