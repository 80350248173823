.email-capture-main {
    width: 100%;
    Border-top: 2px solid #000000;
    height: auto;
}
.email-capture-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40vh;
  background-color: transparent;  /* To blend with landing-container */
  width: 100%;
  text-align: center;
  color: var(--color-text-light);  /* Text color is set to white */
}
.email-capture-round{
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-bg-yellow);
    border-top-right-radius: 200px;
    border-top-left-radius: 200px;
    /* padding-left: 5vh; */
    width: 35%;
}
.email-capture-container h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--color-text-light);  /* Text color is set to white */
}

.email-capture-container p {
  font-size: 17px;
  margin-bottom: 20px;
}

.email-capture-container form {
  display: inline-flex;
}

.email-capture-container input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px 0 0 4px;
}

.email-capture-container button {
  padding: 12px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.email-capture-message {
  color: green; /* for a success message */
  font-size: 16px;
  margin-top: 10px;
}
@media (max-width: 1220px) {
.email-capture-round{
    width: 48%;
}
}
@media (max-width: 1000px) {
.email-capture-round{
    width: 54%;
}
}
@media (max-width: 900px) {
.email-capture-round{
    width: 60%;
}
}
@media (max-width: 768px) {
.email-capture-round{
    width: 90%;
}
.email-capture-round p{
    font-size: 14px;
}
}
@media (max-width: 480px) {
.email-capture-round{
    width: 100%;
}
}
