.forgot-password-container-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  font-family: var(--font-family);
  background-image: radial-gradient(circle, #ffffff, #f5f1ff, #ece4ff, #e4d5ff, #dec7ff, #e8c1f8, #f1baf0, #fab4e5, #ffbbd4, #ffc4c9, #ffd0c6, #fbdbca);
}
.forgot-password-container-center{
    border: 3px solid #000000;
    width:40%;
    align-self: center;
    height: 40%;
    padding-top: 20px;
    background-color: white;
    border-radius: 25px;

}

.forgot-password-container-center h2, .forgot-password-container-center p{
display: flex;
justify-content: center;
align-items: center;

}
.forgot-password-email-container{
    display: flex;
    justify-content: center;
    padding-top: 5vh;
}
.forgot-password-container-center input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 20px 0 0 20px;
}
.email-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.email-message p{
    padding: 20px;

}
.forgot-password-container-center button {
display: flex;
    justify-content: center;
    align-items: center;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: 1px solid #000000;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
}

@media (max-width: 950px) {

.forgot-password-container-center{
    border: 3px solid #000000;
    width: 60%;
    align-self: center;
    height: 40%;
    padding-top: 20px;
    background-color: white;
    border-radius: 25px;

}
}

@media (max-width: 750px) {

.forgot-password-container-center{
    width: 70%;
}
}
@media (max-width: 620px) {

.forgot-password-container-center{
    width: 80%;
}
}
@media (max-width: 550px) {

.forgot-password-container-center{
    width: 90%;
}
}

@media (max-width: 450px) {

.forgot-password-container-center{
    width: 98%;
    height: 60%;
    display: flex;
    flex-direction: column;
}
.forgot-password-email-container{
       display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forgot-password-container-center h2, .forgot-password-container-center p{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;

}
.forgot-password-container-center input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 20px;
}
.forgot-password-container-center button {
display: flex;
    justify-content: center;
    align-items: center;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: 1px solid #000000;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
}
}