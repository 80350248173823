.section-container {
  width: 100%;
  background-color: white;
  font-family: sans-serif;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: relative;
  color: var(--color-bg-darkGrey);
}

.separator-line {
  width: 90%;
  height: 2px;
  background-color: #4a4a4a30;
  margin: 0 auto 2rem auto;
}

.section-container.in-view {
  opacity: 1;
}

.title-section {
  width: 100%;
  padding: 1rem 1.5rem;
  color: var(--color-bg-darkGrey);
  font-weight: 900;
}

.title-section h1 {
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
}

.main-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  gap: 2rem;
  margin-bottom: 8rem;
}

.left-container {
  width: 50%;
  background-color: white;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.2);
    border-radius: 24px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.title-tag-consulting {
  display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;
    background-color: var(--color-bg-darkGrey);
    border-radius: 250px;
    border-top-right-radius: 250px;
    font-size: 30px;
    font-weight: 600;
    color: var(--color-bg-offWhite);
    text-align: center;
}

.consulting-points {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0;
}

.point {
  padding: 1rem;
  background-color: none;
  border-radius: 0.5rem;
}

.point-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-bg-darkGrey);
  margin-bottom: 0.5rem;
}

.point-desc {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
}
.button-container-consulting{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.solution-button {
  background-color: var(--color-bg-yellow);
  color: black;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  align-self: flex-start;
}

.solution-button:hover {
  background-color: #15803d;
}

.right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tagline-wrapper{
width:100%;
}
.tagline-wrapper h2 {
  font-size: 5.5rem;
  font-weight: 400;
  margin: 0.5rem 0;
}

.phrase-container {
  position: relative;
  width: 100%;
  min-height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.phrase {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 2rem);
  font-size: 6.5rem;
  opacity: 0;
  transition: all 0.5s ease;
  text-align: center;
  width: 100%;
  color: var(--color-bg-green);
}

.phrase.active {
  opacity: 1;
  transform: translate(-50%, 0);
}


@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .left-container,
  .right-container {
    width: 100%;
  }

  .title-section h1 {
    font-size: 2.5rem;
  }

  .tagline-wrapper {
    text-align: center;
  }

  .right-container {
    align-items: center;
  }

  .phrase {
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(2rem);
  }

  .phrase.active {
    transform: translateX(-50%) translateY(0);
  }
}

@media (max-width: 480px) {
  .title-section h1 {
    font-size: 2rem;
  }

  .tagline-wrapper h2,
  .phrase {
    font-size: 1.875rem;
  }

  .phrase-container {
    height: 3rem;
  }

  .consulting-points {
    gap: 1rem;
  }

  .point {
    padding: 0.75rem;
  }

  .point-title {
    font-size: 1.125rem;
  }

  .point-desc {
    font-size: 0.875rem;
  }
}
