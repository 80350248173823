.call-to-action-main {
  width: 100%;
  padding-bottom: 15vh;
}
.call-to-action-container {
  background-color: transparent;  /* Make the background blend with the landing-container */
  padding: 50px;
  text-align: center;
  color: var(--color-text-light);
}

.call-to-action-container h2 {
  font-size: 32px;
  margin-bottom: 10px;
  color: var(--color-text-light);
}

.call-to-action-container p {
  font-size: 18px;
  margin-bottom: 40px;
}

.call-to-action-button {
  background-color: var(--color-bg-green);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}