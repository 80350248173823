
.slide-out-container {
  position: fixed;
  top: 9%;
  left: 6.5%;
  height: 91vh;
  width: 40px;
  z-index: 3;
  transition: left 0.5s;
}
.tool-bar-permanent{
  position: fixed;
  top: 9%;
  left: 0%;
  height: 93.5vh;
  width: 5%;
  z-index: 300;
  transition: left 0.5s;
  background-color: var(--color-bg-purple-light);
  border-right: 3px solid var(--color-bg-purple);
  border-top: 3px solid var(--color-bg-purple);
   border-top-right-radius: 5px;
}

.tool-bar-icons-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
}

.tool-bar-icon-button-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-family);
  height: 120px;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  width: 100%;
  cursor: pointer;

}
.tool-bar-icon-button-box-return {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--font-family);
  height: 130px;
  font-size: 14px;
  line-height: 13px;
  font-weight: 800;
  text-align: center;

  width: 100%;
  cursor: pointer;

}
.tool-bar-icon-button-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-left: 8px solid transparent;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.tool-bar-icon-button-background:hover {
  border-left-color: var(--color-bg-purple);
  background-color: rgba(0, 123, 255, 0.1);
}

.tool-bar-icon-button-box img {
  height: 30px;
  width: 30px;
  object-fit: contain;
}
.tool-bar-icon-button-box-return img{
 height: 40px;
  width: 40px;
  object-fit: contain;


}
.tool-bar-icons {
  display: flex;
  align-items: center;
  font-family: var(--font-family);
  height: 40px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
}
.tool-bar-icons-return{
display: flex;
  align-items: center;
  font-family: var(--font-family);
  height: 55px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;


}
.chat-toggle-button{
  position: absolute;
  border: none;
  background: none;
  z-index: 2;
  cursor: pointer;
  top: 40%; /* Middle of the screen vertically */
  left: -300px; /* To the right of the content area */
  transition: left 0.4s, transform 0.1s;

}
.chat-toggle-button img {
  height: 50px; /* Adjust the size as needed */
  width: auto;
  transition: transform 0.5s;
}

.chat-toggle-button.pulled-out-button {
  /* other styles */
  z-index: 2;
  transform: rotate(180deg); /* Rotates the arrow 180 degrees */
}
.content-area {
  position: absolute;
  top: 0;
  left: -20%;
  background-color: white;
  height: 100%;
  width: 20%;
  padding: 10px;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: left 0.5s;
  visibility: hidden; /* Initially hidden */
  z-index: 200;
}

.pulled-out {
  left: 0;

  visibility: visible;
  z-index: 1000; /* Or whatever z-index value you need */
}
.pulled-out-button {
  left: 20%;
  visibility: visible;
}
.slide-out-container.active {
    z-index: 1000;
    /* position: absolute; */
    width: 100vw;
    left: 5%;
    /* transition: left 0.5s; */
    height: 91vh;
    /* top: auto; */
    top: 9%;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensuring the modal stays on top */
}

.modal-content {
  position: relative;
  background: none;
  padding: 20px;
  width: 50%; /* Or any size you desire */
  height: 30vh;
  border-radius: 8px;
}

.modal-content .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: top;
  z-index: 999; /* Ensuring the modal stays on top */
}

.modal-content-custom {
  position: relative;
  background: none;
  padding: 20px;
  width: 50%; /* Or any size you desire */
  min-height: 30vh;
  height: auto;

  border-radius: 8px;
}

.modal-content-custom .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.custom-tool-info-container{
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    min-height: 45vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    color: white;

}
.custom-tool-info{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 40%;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    border: 3px solid black;
    overflow-y: auto;
}
.custom-tool-info-title{
display: flex;
align-items: center;
justify-content: center;
width: 100%;
border-bottom: 3px solid var(--color-bg-purple-light);

}
.custom-tool-info-title h2{
padding: 10px;
color: black;
}
.custom-tool-info-text{
padding: 10px;
color: black;

}
.custom-tool-info-text p{
padding-bottom: 10px;
font-size: 14px;
}
/* General styles for the FileUploader */
.FileUploader {
    z-index: 999;
    font-family: var(--font-family);
     border-radius: 5px;
}

/* Style for a successful upload */
.successful-upload {
    border-color: #4CAF50; /* green color */
    background-color: #32a846; /* light green background */
    border-radius: 5px;
    z-index: 9999;
}

/* CSS */
.file-upload-container {
  display: flex; /* Align children in a row */
  align-items: center; /* Center children vertically */
  justify-content: flex-start; /* Align children to the start of the container */
  margin-top: 25px;
  padding: 15px;
  /*background-color: #011735;*/
  border-radius: 5px;
  border: 1px solid #FFFFFF;

}
.custom-file-button,
.file-name-display,
.uploader-button {
height: 6vh;
}
.custom-file-button {
    width: 16%;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    background: clear;
    color: #000000;
    cursor: pointer;
    outline: 1px solid;
    outline-color: #FFFFFF;
    border-radius: 5px;
    border: none;
}

.file-name-display {
  flex-grow: 1; /* Allow the file name input to grow and fill the space */
  padding-left: 10px;
  margin-right: 10px;
  margin-left: 10px;
  /* Style your input */
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    background-color: transparent;
     border: 1px solid;
    border-color: #FFFFFF;
    border-radius: 5px;
    border: none;
}
.centered-content {
  display: flex;
  justify-content: center;
}

.source-container {
  position: relative;
  padding-bottom: 50px;
}

.pdf-viewer {
  width: 90%;        /* Set the desired width */
  margin: 0 auto;    /* Center the element within its parent */
  display: flex;
  padding-bottom: 20px;
  flex-direction: column; /* Keeps the children (like your button and iframe) stacked vertically */
}

.close-button-container {
  display: flex;
  justify-content: space-between; /* Aligns the close button to the right */
  width: 100%; /* Ensures the container extends the full width of its parent */
  margin-bottom: 10px;
}


.close-button,.chat-single-button {
display: flex;
    min-width: 45px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    outline: 1px solid transparent;
    outline-color: #FFFFFF;
    border-radius: 5px;
    background: #FF4820;
    border: 1px solid #FF4820;
    color: #fff;
    cursor: pointer;
    outline: 1px solid transparent;
    outline-color: #FFFFFF;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: black 1px solid;
}


.uploader-button {
    width: 15%;
    text-align: center;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    outline: 1px solid transparent;
    outline-color: #FFFFFF;
    border-radius: 5px;
    background: #FF4820;
    border: 2px solid #FF4820;
    color: #fff;
    cursor: pointer;
    outline: 1px solid transparent;
    outline-color: #FFFFFF;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.custom-file-input{
width: 60%;
}
.error-message {
  display: flex;
  color: #D32F2F;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  font-family: var(--font-family);
  align-content: center;
  align-text: center;
  justify-content: center;

}
.upgrade {
  display: flex;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  font-family: var(--font-family);
  align-content: center;
  align-text: center;
  justify-content: center;

}
/* Style for the upload button */
.topMessage{
    text-align: center;
    justify-content: center;
    algign-items: center;
}
.source-button{

  font-family: var(--font-family);
  padding: 4px;
  font-size: 18px;
  background-color: transparent;
   color: var(--color-text);
  border: none;
  cursor: pointer;
  margin-left: 6px;
  margin-bottom: 5px;
}
.source-container.disabled {
    pointer-events: none; /* Prevents all click events */
    opacity: 0.5; /* Makes the container look faded */
    /* Additional styling as needed */
}
.loading-pdf{
    font-family:var(--font-family);
    color: var(--color-text);
}
.title-chat {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
  margin: 0 20px; /* This gives some space on both sides of the title */
}
.top-bar-chat {
    font-family: var(--font-family);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all .3s ease;
    z-index: 1000;
  width: 100%;
  display: flex;
    height: 9vh;
  background-color: #ffffff;
  align-items: center;
  padding: 10px 50px; /* Adjust as needed */
  justify-content: space-between;
}
@media (max-height: 1100px){
.tool-bar-permanent{
  position: fixed;
  top: 9%;
  left: 0%;
  height: 93.5vh;
  z-index: 300;
  transition: left 0.5s;
  background-color: var(--color-bg-purple-light);
  border-right: 3px solid var(--color-bg-purple);
  border-top: 3px solid var(--color-bg-purple);
   border-top-right-radius: 5px;
}

}
@media (max-width: 1250px) {
.modal-content,.modal-content-custom{
    width: 60%;
}
}
@media (max-width: 1000px){
.slide-out-container {
  position: fixed;
  top: 9%;
  left: 6.5%;
  height: 91vh;
  width: 40px;
  z-index: 3;
  transition: left 0.5s;
}
.pulled-out{
width: 30%;
}
.pulled-out-button{
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 40%; /* Middle of the screen vertically */
  left: 30vw; /* To the right of the content area */
  transition: left 0.5s, transform 0.5s;
}
}
@media (max-width: 780px) {
.modal-content,.modal-content-custom{
    width: 80%;
}
.pulled-out{
width: 40%;
}
.pulled-out-button{

}
.pulled-out-button{
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 40%; /* Middle of the screen vertically */
  left: 40vw; /* To the right of the content area */
  transition: left 0.5s, transform 0.5s;
}
}
@media (max-width: 550px) {
.modal-content,.modal-content-custom{
    width: 90%;
}
}
@media (max-width: 450px) {
.modal-content,.modal-content-custom{
    width: 99%;
}
.custom-file-button{
    font-size: 12px;
    line-height: 16px;
}
.uploader-button{
   font-size: 12px;
    line-height: 16px;
}
.close-modal-btn{
    width: 15px;
}
.slide-out-container {
  position: fixed;
  top: 9%;
  left: 6.5%;
  height: 91vh;
  width: 40px;
  z-index: 3;
  transition: left 0.5s;
}
.pulled-out{
width: 50%;
}
.pulled-out-button{
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 40%; /* Middle of the screen vertically */
  left: 50vw; /* To the right of the content area */
  transition: left 0.5s, transform 0.5s;
}
}