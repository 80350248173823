.chat-history {
  width: 10%;   /* Adjust as needed */
  height: 100vh;  /* Takes the full height of the viewport */
  overflow-y: auto; /* Makes it scrollable if content exceeds its height */
  border-right: 2px solid #ddd;  /* A border to separate it from the main content, adjust as needed */
  padding: 20px;  /* Some space for the chat logs */
  box-shadow: 2px 0px 5px rgba(0,0,0,0.1); /* Optional shadow for depth */
}
/* Style ChatHistory as needed, for example: */

.chat-history {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 28px; /* This will make the text color white */
}

/* If you want specific control over different elements, you can target them individually */
.chat-history .session-id-text {
    color: #fff;
}

.chat-history .chat-messages-container p {
    color: #fff;
}

