/* Data Optimization Page Styles */
:root {
  --color-bg-yellow: #f0dd5f;
  --color-bg-green: #156e12;
  --color-bg-black: #071a14;
  --color-bg-offWhite: #e9eae0;
  --color-bg-darkGrey: #0f2922;
}

.data-optimization-page {
  font-family: var(--font-family);
  color: var(--color-bg-black);
  background-color: var(--color-bg-offWhite);
  position: relative;
  overflow-x: hidden;
}

/* Data background effect */
.data-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.08;
  /* Background pattern can be added here */
}

.data-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Section Styling */
.data-optimization-page section {
  padding: 5rem 0;
}

.data-section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.data-section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-bg-black);
  margin-bottom: 1rem;
}

.data-section-header.data-light h2 {
  color: white;
}

.data-section-description {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 1.5rem auto 0;
  color: rgba(255, 255, 255, 0.9);
}

.data-underline {
  height: 4px;
  width: 80px;
  background-color: var(--color-bg-yellow);
  margin: 0 auto;
}

/* Data Solutions Banner Section */
.data-banner {
  background-color: var(--color-bg-black);
  color: white;
  min-height: 90vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.data-banner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-bg-yellow);
}

.data-banner-wrapper {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 7rem;
}

.data-banner-content {
  flex: 1;
  padding-right: 2rem;
  max-width: 600px;
}

.data-banner h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.data-title-impact {
  font-size: 5.5rem;
  font-weight: 900;
  color: var(--color-bg-yellow);
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.data-title-focus {
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -1px;
}

.data-banner-divider {
  width: 80px;
  height: 4px;
  background-color: var(--color-bg-green);
  margin: 2rem 0;
}

.data-banner-tagline {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  opacity: 0.9;
}

.data-banner-graphic {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.data-chart-graphic {
  width: 100%;
  max-width: 500px;
  height: 400px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 2rem;
  border-bottom: 3px solid var(--color-bg-green);
  border-left: 3px solid var(--color-bg-green);
}

.data-chart-bar {
  width: 50px;
  background-color: rgba(240, 221, 95, 0.7);
  margin: 0 10px;
  position: relative;
  animation: dataBarGrow 2.5s ease-out forwards;
  border-radius: 4px 4px 0 0;
}

.data-chart-bar:nth-child(1) {
  height: 60%;
  animation-delay: 0.1s;
}

.data-chart-bar:nth-child(2) {
  height: 80%;
  animation-delay: 0.3s;
}

.data-chart-bar:nth-child(3) {
  height: 100%;
  animation-delay: 0.5s;
}

.data-chart-bar:nth-child(4) {
  height: 75%;
  animation-delay: 0.7s;
}

.data-chart-bar:nth-child(5) {
  height: 90%;
  animation-delay: 0.9s;
}

@keyframes dataBarGrow {
  0% {
    height: 0;
  }
  100% {
    height: inherit;
  }
}

.data-cta-button {
  background-color: var(--color-bg-green);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.data-cta-button:hover {
  background-color: #1a8417;
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

/* Data Approach Section */
.data-approach-section {
  background-color: white;
  position: relative;
  padding: 7rem 0;
}

.data-approach-section::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
  height: 100%;
  background-color: var(--color-bg-yellow);
  opacity: 0.1;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
}

.data-approach-wrapper {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 4rem;
}

.data-approach-stat-card {
  flex: 0 0 300px;
  height: 300px;
  border-radius: 20px;
  background: linear-gradient(135deg, var(--color-bg-green), var(--color-bg-darkGrey));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.data-approach-stat-card::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, var(--color-bg-yellow) 0%, transparent 70%);
  opacity: 0.1;
  top: -25%;
  left: -25%;
}

.data-stat-card-inner {
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
}

.data-stat-value {
  font-size: 5rem;
  font-weight: 800;
  color: var(--color-bg-yellow);
  line-height: 1;
  margin-bottom: 1rem;
}

.data-stat-description {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 300;
}

.data-approach-features {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.data-approach-feature {
  display: flex;
  gap: 1.5rem;
  background-color: var(--color-bg-offWhite);
  padding: 2rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.data-approach-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.data-feature-icon {
  flex: 0 0 60px;
}

.data-icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-bg-green);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.data-icon-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, var(--color-bg-yellow) 0%, var(--color-bg-green) 70%);
  opacity: 0.3;
}

.data-feature-content {
  flex: 1;
}

.data-feature-content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--color-bg-darkGrey);
}

.data-feature-content p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
}

/* Data Services Section */
.data-services-section {
  background-color: var(--color-bg-black);
  color: white;
  position: relative;
  padding: 7rem 0;
}

.data-services-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 6px;
  background-color: var(--color-bg-green);
}

.data-services-container {
  display: flex;
  margin-top: 4rem;
  position: relative;
}

.data-service-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1;
}

.data-service-column.data-middle {
  flex: 1.2;
  margin-top: -3rem;
  z-index: 2;
}

.data-service-item {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  height: 100%;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.data-service-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-bg-yellow);
}

.data-service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.08);
}

.data-service-item.data-featured {
  background-color: var(--color-bg-green);
  padding: 3rem 2rem;
  border-radius: 15px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

.data-service-item.data-featured::before {
  height: 0;
}

.data-service-item.data-featured::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at top right, rgba(240, 221, 95, 0.3), transparent 70%);
}

.data-service-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 1.5rem;
  position: relative;
}

.data-service-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: var(--color-bg-yellow);
  border-radius: 50%;
  opacity: 0.8;
}

.data-service-icon.data-large {
  width: 90px;
  height: 90px;
}

.data-service-item h3 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.data-service-item p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.data-services-cta {
  text-align: center;
  margin-top: 4rem;
}

.data-secondary-button {
  background-color: transparent;
  color: var(--color-bg-yellow);
  border: 2px solid var(--color-bg-yellow);
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.data-secondary-button:hover {
  background-color: var(--color-bg-yellow);
  color: var(--color-bg-black);
}

/* Data Value Section (renamed from benefits) */
.data-value-section {
  background-color: var(--color-bg-offWhite);
  position: relative;
  padding: 7rem 0;
}

.data-value-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(var(--color-bg-yellow) 1px, transparent 1px);
  background-size: 30px 30px;
  opacity: 0.1;
  z-index: 0;
}

.data-value-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  position: relative;
  z-index: 1;
}

.data-value-card {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.data-value-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
}

.data-value-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-bg-green);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.data-value-card:hover::after {
  transform: scaleX(1);
}

.data-value-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: var(--color-bg-offWhite);
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-value-icon::before {
  content: '✓';
  font-size: 1.8rem;
  color: var(--color-bg-green);
}

.data-value-card h3 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: var(--color-bg-darkGrey);
}

.data-value-card p {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
}

/* Contact Section */
.data-contact-section {
  background-color: white;
  position: relative;
  padding: 7rem 0;
}

.data-contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, rgba(240, 221, 95, 0.05) 25%, transparent 25%, transparent 50%, rgba(240, 221, 95, 0.05) 50%, rgba(240, 221, 95, 0.05) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  opacity: 0.3;
}

.data-contact-flex {
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
  z-index: 1;
}

.data-contact-content {
  flex: 1;
}

.data-contact-content h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: var(--color-bg-darkGrey);
}

.data-contact-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.data-contact-benefits {
  list-style: none;
  padding: 0;
}

.data-contact-benefits li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.05rem;
}

.data-contact-benefits li::before {
  content: '✓';
  position: absolute;
  left: 0;
  top: 0;
  color: var(--color-bg-green);
  font-weight: bold;
}

.data-contact-form-container {
  flex: 1;
  background-color: var(--color-bg-offWhite);
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.data-contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.data-contact-form input,
.data-contact-form textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  transition: all 0.3s ease;
}

.data-contact-form input:focus,
.data-contact-form textarea:focus {
  outline: none;
  border-color: var(--color-bg-green);
  box-shadow: 0 0 0 3px rgba(21, 110, 18, 0.2);
}

.data-contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.data-contact-form button {
  align-self: flex-start;
}
/* Add these styles to your DataOptimization.css file */

/* Form Notification Styles */
.data-form-notification {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 15px;
  animation: dataSlideIn 0.4s ease-out;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.success-notification {
  background-color: rgba(21, 110, 18, 0.1);
  border-left: 4px solid var(--color-bg-green);
}

.error-notification {
  background-color: rgba(220, 53, 34, 0.1);
  border-left: 4px solid #dc3545;
}

.data-notification-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 1rem;
  flex-shrink: 0;
}

.success-notification .data-notification-icon {
  background-color: var(--color-bg-green);
  color: white;
}

.error-notification .data-notification-icon {
  background-color: #dc3545;
  color: white;
}

.data-notification-message {
  flex-grow: 1;
  font-size: 0.95rem;
  line-height: 1.5;
}

.success-notification .data-notification-message {
  color: var(--color-bg-green);
}

.error-notification .data-notification-message {
  color: #dc3545;
}

.data-notification-close {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s;
}

.data-notification-close:hover {
  color: var(--color-bg-black);
}

/* Button state for form submission */
.data-cta-button.data-submitting {
  background-color: rgba(21, 110, 18, 0.7);
  transform: none;
  cursor: not-allowed;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.data-cta-button.data-submitting:hover {
  background-color: rgba(21, 110, 18, 0.7);
  transform: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.data-cta-button.data-submitting::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: dataLoadingShimmer 1.5s infinite;
}

@keyframes dataSlideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dataLoadingShimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
/* Responsive Styling */
@media (max-width: 1200px) {
  .data-banner h1 {
    font-size: 4rem;
  }

  .data-title-impact {
    font-size: 4.5rem;
  }

  .data-title-focus {
    font-size: 2.8rem;
  }

  .data-contact-flex {
    gap: 2rem;
  }

  .data-value-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .data-banner-wrapper {
    flex-direction: column;
  }

  .data-banner-content {
    padding-right: 0;
    max-width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }

  .data-banner-divider {
    margin: 2rem auto;
  }

  .data-approach-wrapper {
    flex-direction: column;
  }

  .data-approach-stat-card {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 3rem;
  }

  .data-services-container {
    flex-direction: column;
  }

  .data-service-column.data-middle {
    margin-top: 0;
    order: -1;
    margin-bottom: 2rem;
  }

  .data-service-item.data-featured {
    padding: 2rem;
  }

  .data-contact-flex {
    flex-direction: column;
  }

  .data-contact-content,
  .data-contact-form-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .data-optimization-page section {
    padding: 5rem 0;
  }

  .data-container {
    padding: 0 1.5rem;
  }

  .data-banner h1 {
    font-size: 3rem;
  }

  .data-title-impact {
    font-size: 3.5rem;
  }

  .data-title-focus {
    font-size: 2.2rem;
  }

  .data-banner-tagline {
    font-size: 1.2rem;
  }

  .data-feature-content h3 {
    font-size: 1.3rem;
  }

  .data-approach-feature {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .data-feature-icon {
    margin-bottom: 1rem;
  }

  .data-value-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .data-banner {
    min-height: auto;
    padding: 6rem 0;
  }

  .data-banner h1 {
    font-size: 2.5rem;
  }

  .data-title-impact {
    font-size: 3rem;
  }

  .data-title-focus {
    font-size: 1.8rem;
  }

  .data-banner-tagline {
    font-size: 1rem;
  }

  .data-chart-graphic {
    height: 300px;
  }

  .data-contact-form-container {
    padding: 2rem;
  }

  .data-service-item h3 {
    font-size: 1.4rem;
  }
}