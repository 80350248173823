/* Hero Container */
.hero-container {
  position: relative;
  /* Use min-height so the container grows as needed but still can fill the screen */
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
}

/* Video Overlay */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Subtle bottom gradient */
.ending-gradient {
  height: 100px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(233, 234, 224, 0), #e9eae0);
}

/* Hero Content */
.hero-content {
  position: relative;
  z-index: 2;
  /* Give some default padding that we’ll adjust in media queries */
  height: 100%;
  padding: 2rem 4rem;
  padding-bottom: 10rem;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* Box containing our text and stats */
.hero-content-box {
  background: rgba(255, 255, 255, 0.2); /* More transparency for a 'glass' effect */
  border-radius: 24px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari compatibility */
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 3rem;
  max-width: 100%; /* Constrain the width a bit */
  width: 63%;
}

.hero-content-box-left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Text Overlay */
.overlay-text {
  max-width: 800px; /* Keep text narrower for readability */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}

.overlay-text h1 {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.2;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  text-shadow: 0.1px 0 0 currentColor;
}

.overlay-text h2 {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #4a4a4a;
  max-width: 600px;
  margin-bottom: 3rem;
  text-align: center;
}

/* DNA Image */
.dna-image {
  max-width: 800px; /* Large screens */
  width: 100%;      /* Always scale down to container width on smaller screens */
  margin: -2.5rem 0;
  display: block;
  z-index: 0;
}

/* Stats Section */
.stats-container {
  display: flex;
  gap: 3rem;
  margin-top: 4rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.stat-number {
  font-size: 1.5rem;
  font-weight: 700;
  color: #f0dd5f;
  transition: color 0.3s ease;
}

.stat-label {
  font-size: 1rem;
  color: #4a4a4a;
  transition: color 0.3s ease;
}

/* Hover States */
.stat-item:hover {
  transform: scale(1.05);
}

.stat-item:hover .stat-number {
  color: #0f2922 !important;
}

.stat-item:hover .stat-label {
  color: #0f2922 !important;
}

/* ------------------- MEDIA QUERIES ------------------- */

/* Large Tablets and down */
@media (max-width: 1024px) {
  .overlay-text h1 {
    font-size: 2.8rem;
  }
.hero-content {
  padding: 4rem 2rem;
}
.hero-content-box{
    width: 85%;
}
  .stat-number {
    font-size: 1.4rem;
  }
.hero-container {
  min-height: 100vh;
}

  .hero-content-box {
    padding: 2rem;
  }
}

/* Tablets and down */
@media (max-width: 768px) {
  .hero-content {
    padding: 3rem 1rem;
    padding-bottom: 6rem;
  }
.hero-content-box{
    width: 90%;
}
  .overlay-text h1 {
    font-size: 2.4rem;
  }

  .overlay-text h2 {
    font-size: 1.1rem;
  }

  .stats-container {
    flex-direction: column;
    gap: 2rem;
  }
}

/* Mobile */
@media (max-width: 480px) {
  .overlay-text h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .overlay-text h2 {
    font-size: 1rem;
  }
.hero-content-box{
    width: 100%;
}
  .dna-image {
    margin: -1.5rem 0;
  }

  .stats-container {
    gap: 1.5rem;
  }

  .stat-number {
    font-size: 1.4rem;
  }
}
