/* Payments.css */

.flex-container {
  display: flex;
  justify-content: space-between;
}

.payment-form {
  flex: 1;  /* this will make sure the form takes up only the space it needs */
}

.checkout-pricing-container {
  display: flex;
  flex: 1;  /* this will give the pricing container twice the width of the payment form */
  background-image: linear-gradient(to top, #040c18, #051021, #04142b, #011735, #00193f);
  padding: 20px;
  height: 90vh;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.checkout-pricings {
  display: flex;
  width: 50%; /* Takes up full width */
  justify-content: space-around; /* Distributes the features evenly */
  font-family: var(--font-family);
  height: 50vh;
}

.checkout-starter, .checkout-pro , .checkout-Premium {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 2rem 2rem 2rem; /* 20px padding-top as you specified, and 2rem on other sides */
  border: 1px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.checkout-starter{
 background-image: linear-gradient(to top, #ffffff, #e2dcf5, #c6bae9, #ad97dc, #9575cd);
}
.checkout-pro{
  background-image: linear-gradient(to top, #ffffff, #f1e6f9, #eecbeb, #f1aed3, #f48fb1);
}

.checkout-Premium{
  background-image: linear-gradient(to top, #ffffff, #ffe8ff, #ffcbdc, #ffb79a, #ffb74d);
}

.checkout-starter h2, .checkout-pro h2, .checkout-Premium h2{
    color: #000;
    font-size: 35px;
    font-weight: 900;
    line-height: 110%;
    outline-color: #000;
}

.checkout-starter h3,
.checkout-pro h3,
.checkout-Premium h3 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.checkout-starter h5,.checkout-pro h5, .checkout-Premium h5{
font-size: 20px;
text-align: left;
margin-bottom: 10px;
}

.checkout-starter h4,
.checkout-pro h4,
.checkout-Premium h4 {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
}

#payment-form {
  height: 90.5vh;
  display: flex;
  flex-direction: column;  /* stack children vertically */
  justify-content: center; /* center children vertically */
  align-items: center;     /* center children horizontally */
  background-color: #00193f;
}


.checkout #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.checkout #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.checkout button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 31%;
}

.checkout button:hover {
  filter: contrast(115%);
}

.checkout button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
