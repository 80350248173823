.auth-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 90vh;
background-color: var(--color-bg-offWhite);
}
.auth-container-left{
    display: flex;
    height: 90%;
    width: 50vw;
    background-color: #ae67fa;
    align-items: center;
    justify-content: center;
}
.auth-container-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    height: 100%;

}
.auth-container-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    width:100%;
    align-self: center;
    height: 70%;
    background-color: white;
    border-radius: 8px;
}
.auth-container-login-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 15%;

}

.login-title,.signup-title{
    display: flex;
    font-family: var(--font-family);
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    cursor: pointer;
    color: var(--color-bg-darkGrey);
}
.login-title{
    margin-right: 10%;
        width: 30%;
        margin-left: 10%;
}
.signup-title{
     margin-right: 10%;
        width: 30%;
        margin-left: 10%;
}
/* Styles for the active title */
.active-title {
    background-color: var(--color-bg-yellow);
    border-radius: 20px;
    border: 1px solid #FFC178;
}

/* Optional: Styles for the inactive title for more control */
.inactive-title {
    background-color: transparent;
    border-radius: 0;
}



.toggle-buttons {
  margin-bottom: 1rem;
}

.active-button {
  background-color: #007BFF;
  color: white;
}

.switch-auth-method{
  margin-top: 2vh;
  display: flex;
  font-family: var(--font-family);
  font-size:20px;
  height: 5vh;
  align-items: center;
  color: #000000;
  width: 70%;
  border-radius: 5px;
  border: 2px solid #E0E0E0;
    background-color: #f6f6f6;
  justify-content: center;
  padding-top: 5px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed; /* Fixed position to the window, not any container */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background: rgba(0, 0, 0, .9); /* Black background with opacity */
  z-index: 1000; /* High z-index so it's on top of other content */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}
.container h2{
color: black;

}
.popup-content {
  display: flex;
  flex-direction: column;
  width: 40%; /* Or any specific width */
  padding: 20px;
  background-color: #ffffff;
  color: #ffffff;
  height: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Optional: for effect */
  text-align: center; /* If you want to center the text */
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #000000;

  /* Add other stylings as needed, depending on your design */
}
.logout-container{
    display:flex;
    justify-content: center;
    padding-top: 10px;
    color: #000000;
}

.logout p {
    display:flex;
    font-family: var(--font-family);
    color: black;
    font-weight: 600;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    align-content: center;
    padding:4px;
    border-radius: 4px;
}
.exit-button{
 font-family: var(--font-family);
 color:white;
 font-weight: 600;
 font-size: 20px;

}
@media (max-width: 1100px) {
 .auth-container-center{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.popup-content{
width: 60%;
}
.switch-auth-method{
height: 7.5vh;
}
}
@media (max-width: 830px) {
.popup-content, .auth-container-right{
width: 70%;
}
}
@media (max-width: 700px){
.popup-content, .auth-container-right{
width: 80%;
}
.form{
    width: 80%;
}
}
@media (max-width: 480px) {
.popup-content, .auth-container-right {
width: 100%;
}

.form{
    width: 90%;
}
}