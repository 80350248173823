/* Legal.css */

:root {
  --color-bg-purple-light: #f3e8ff; /* Adjust this value to match your exact color */
}

.legal-container {
  background-color: var(--color-bg-purple-light);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.legal-content {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  max-width: 800px;
  width: 100%;
}

.legal-content-header {
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.legal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.legal-date {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.legal-content-body {
  padding: 1.5rem;
}

.legal-section {
  margin-bottom: 2rem;
}

.legal-section-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.5rem;
}

.legal-subsection-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #374151;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.legal-text {
  color: #4b5563;
  line-height: 1.5;
}

.legal-list, .legal-ordered-list {
  margin-left: 1.5rem;
  color: #4b5563;
}

.legal-list {
  list-style-type: disc;
}

.legal-ordered-list {
  list-style-type: decimal;
}