.main-toolbar-container{
    overflow-y: auto; /* Adds vertical scrollbar when content overflows */
    max-height: 100%; /* Optional: Sets a maximum height */
    }
.userFiles-container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: auto;
    background-color: transparent; /* Corrected color value */
    border-radius: 10px;
    font-family: var(--font-family);
}

.userFiles-button-container, .return-to-chat-button-container,.custom-tool-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #F6F6F6;
    text-align: left;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    text-decoration: underline; /* Underlines text within the container */
}
.return-to-chat-button-container{
    text-decoration: none; /* Underlines text within the container */
    background-color: var(--color-bg-purple-lightest); /* Corrected color value */
    border: 2px solid var(--color-bg-purple-light); /* Corrected color value */
}
.return-to-chat-button{
    text-decoration: none; /* Corrected color value */
    align-items: center;
    justify-content: center;outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 600;
    padding-left: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;

}

.main-toolbar-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: var(--font-family);
}
.files-title, .tags-title, .custom-tools-title{
    border-bottom: 2px solid grey;

}
.tool-bar-filled-content,.tfool-bar-filled-content-custom-tools {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    height: auto; /* Height set for file container */
    max-height: 40vh;
    overflow-y: scroll; /* Added to enable scrolling */
    margin-bottom: 10px;
}

.uploaded-files-button, .custom-tools-button, .tag-button{
    outline: none;
    border: none;
    background-color: transparent;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 600;
    padding-left: 5px;
    text-align: left;
    width: 100%;
    height: 100%;
    cursor: pointer;

}

.file-container, .tag-container, .tool-container {
  padding: 10px;
  margin: 5px 0;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden; /* Hide overflow */

}

.file-container:hover, .tag-container:hover,.tool-container:hover {
  background-color: #f0f0f0;
}

.file-container.selected, .tag-container.selected, .tool-container.selected{
  border-color: #007bff;
  background-color: #e6f2ff;
}
.file-container-tools{
    position: relative;
    margin: 2px;
    padding: 8px;
    border: none;
    border-radius: 5px;
    min-height: 30px;
    overflow: hidden;
    color: black;
    cursor: pointer;
}




.chat-button {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 11;
    transition: opacity 0.3s;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 50px;
    padding-left: 5px;
    margin-right: 3px;
    background-color: transparent;
    font-family: var(--font-family);
    color: black;
    font-weight: 700;
    cursor: pointer;
}

.tool-use-button {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 11;
    transition: opacity 0.3s;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 50px;
    padding-left: 5px;
    margin-right: 3px;
    background-color: red;
    font-family: var(--font-family);
    color: black;
    font-weight: 700;
    cursor: pointer;
}


.chat-button img {
    height: 50px;
    width: auto;
    transition: transform 0.5s;
}

.chat-button:hover ~ ::after {
    opacity: 1; /* Corrected usage of ::after for hover effect */
}
.files-options-container,.tag-options-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
    border: 3px solid black;
    border-radius: 10px;

    }
.files-options-container-in-tags{
 display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
    border: 3px solid black;
    border-radius: 10px;
    background-color: var(--color-bg-orange-lightest);

}
.options-container-title{
    display: flex;
    padding-left: 5px;
    padding-bottom: 5px;
}

.selected-file-options-title,.selected-tag-options-title{
    display: flex;
    text-align: left;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-left: 3px;
}
.file-name, .tag-name {
  margin-left: 3px;
  font-weight: 600;
  overflow: hidden;
}
.chatPDF-button-container,.delete-button-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.add-tag-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.add-tag-button{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 30%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #F6F6F6;
    text-align: left;
    border: 2px solid #E0E0E0;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
}
.chatPDF-button,.delete-button{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #F6F6F6;
    text-align: left;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    cursor: pointer;
}
.add-tag-input{
 display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
    border: 2px solid #E0E0E0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}
.individual-files-container,.individual-tags-container,.files-in-tag-container,.custom-tools-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    margin-top: 35px;
    margin-bottom: 10px;
}


.cancel-button-delete{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #F44336;
    text-align: left;
    border: 2px solid #B71C1C;
    border-radius: 10px;
    cursor: pointer;
    color: white;
        box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

}
.confirm-button{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #81C784;
    text-align: left;
    border: 2px solid #43A047;
    border-radius: 10px;
    cursor: pointer;
    color: black;
    box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

}
.file-options-top-container,.tag-options-top-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.options-close-button{
    display: flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
}

.untagged-files-background{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #F6F6F6;
    text-align: left;
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    font-family: var(--font-family);
    text-align: left;
    align-items: left;


}
.untagged-files-separator{
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline; /* Underlines text within the container */

}