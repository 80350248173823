.main-account-container {
  min-height: 90vh;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  text-align: center;
  width: 100%;
  font-family: var(--font-family);
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  background-image: linear-gradient(to bottom, #ffdfb9, #ffe1bd, #ffe3c1, #ffe5c5, #ffe7c9, #ffe8d2, #ffe9da, #ffebe2, #ffefee, #fff4f8, #fefafd, #ffffff);
  padding-bottom: 200px;
}
.account-settings-title{
  padding-top: 30px;

}

.account-settings-title h1{
font-size: 42px;
font-weight: 800;
}


.settings-box-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  height: auto;
  margin-top: 50px;
}
.settings-box-title, .settings-box-title h2{
    display: flex;
    width: 40%;
    color: var(--color-bg-purple);

}
.settings-box-general{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    width: 40%;
    height: auto;
    background-color: white;
    border-radius: 20px;
    border: 2px solid black;
    box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.2);
    margin: 5px;
}
.general-account-settings-box-main{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
}
.general-account-settings-box-main-end{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    padding: 10px;


}
.general-account-settings-box-left{
    display: flex;
    flex-direction: column;
    width: 80%;
    text-align: left;
    padding-left: 30px;
}
.general-account-settings-box-right{
    display: flex;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}
.general-account-settings-box-right h4{
    color: #3f92ff;
}
.general-account-settings-button{
    align-items: center;
    width: 70%;
    height:auto;
    font-family: var(--font-family);
    min-height: 35px;
    text-align: center;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    color: #3f92ff;
    font-size: 18px;
    font-weight: 650;
    cursor: pointer;

}
.popup-cancel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000096;/*rgba(0, 0, 0, 0.9);*/ /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensuring the modal stays on top */
}

.popup-cancel-overlay-content {
  position: relative;
  background: white;
  padding: 20px;
  width: 45%; /* Or any size you desire */
  height: 50vh;
  border-radius: 12px;
  border: 4px solid black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); /* Adding a subtle shadow */
}
.popup-cancel-overlay-flex{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}
.popup-cancel-overlay-title{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
}
.popup-cancel-overlay-paragraph-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 50%;

}
.popup-cancel-overlay-paragraph, .popup-cancel-overlay-paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 90%;
    height: 100%;
    background-color: var(--color-bg-orange-light);
    border-radius: 5px;
    text-align: left;
    padding: 25px;
}
.popup-cancel-overlay-button-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;

}
.popup-cancel-overlay-button-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 70%;
    height: 7vh;
    margin-top: 30px;
}
.popup-cancel-overlay-button-section-update{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 70%;
    height: 7vh;
    margin-top: 30px;
    justify-content: center;

}
.popup-cancel-overlay-button-confirm,.popup-cancel-overlay-button-undo{
    display: flex;
    font-family: var(--font-family);
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 100%;
    font-size: 12px;
    font-weight: 800;
    border-radius: 10px;
    border: 3px solid var(--color-bg-orange);
    background-color: transparent;
    cursor: pointer;
}
.popup-cancel-overlay-button-confirm{
    background-color: var(--color-bg-orange);
}

@media (max-width: 1000px) {
.settings-box-title{
    width: 60%;
}
.settings-box-general{
    width: 60%;
}
.popup-cancel-overlay-content{
    width: 65%;
    }
}
@media (max-width: 700px) {
.settings-box-title{
    width: 70%;
}
.settings-box-general{
    width: 70%;
}
.popup-cancel-overlay-content{
    width: 75%;
}
}

@media (max-width: 550px) {
.settings-box-title{
    width: 80%;
}
.settings-box-general{
    width: 80%;
}
.popup-cancel-overlay-content{
    width: 85%;
}
}


@media (max-width: 400px) {
.settings-box-title{
    width: 95%;
}
.settings-box-general{
    width: 95%;
}
.popup-cancel-overlay-content{
width: 95%;
}
}