.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family);
  width: 100%;

}
.forgot-password-container{
    display: flex;
    width: 100%;
    justify-content: right;
}
.forgot-password{
    display:flex;
    font-family: var(--font-family);
    color: #007BFF;
    font-weight: 600;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    align-content: center;
}
h2 {
   font-size: 25px;
   color: #000000;
   padding: 10px;
}
.container h2{
    color: white;
}
.switch-auth-method {
  color: #ffffff;
}
.form-login {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
}

.message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
  color: #ff0000;  /* Red text color for all message types */
}

.message.error {
  background-color: #ffebee;
  border: 1px solid #ef9a9a;
}

.message.success {
  background-color: #e8f5e9;
  border: 1px solid #a5d6a7;
}

.message.info {
  background-color: #e3f2fd;
  border: 1px solid #90caf9;
}

.input-login,.input-login-special-space {
  height: 5vh;
  border-radius: 4px;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #ced4da;
  background-color: #f6f6f6;
}
.input-login-special-space{
margin-bottom: 2vh;
}
.button-login {
  margin-top: 4vh;
  padding: 0.5rem;
  height: 6vh;
  border-radius: 4px;
  background-color: var(--color-bg-green);
  color: white;
  font-family: var(--font-family);
  font-size: 20px;
}

/* Existing styles here */

.form-common {
   display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.reset-password-container{
    display: flex;
    color: black;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100%;
    font-family: var(--font-family);
    background-image: radial-gradient(circle, #e2c6ff, #e7d5ff, #ede3ff, #f5f1ff, #ffffff);

}
.reset-password-center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 70%;
    background-color: white;
    border-radius: 8px;
}
.reset-password-message{
padding-top: 20px;
color: #000000;
}
@media (max-width: 1100px) {
 .reset-password-center{
 width: 60%;
 }
}
@media (max-width: 830px) {
.reset-password-center{
 width: 70%;
 }
}
@media (max-width: 700px){
.reset-password-center{
 width: 80%;
 height: 60%;
 }
}
@media (max-width: 480px) {
.reset-password-center{
 width: 90%;
 height: 60%;
 }
 .input-login,.input-login-special-space {
 height: 7vh;

 }
}
