/* This will reset any default margins and paddings, and set a consistent box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.Blog-list-container{
color: var(--color-bg-darkGrey);

}
/* This will style the overall background of the blog */
.blog-background {
  display: flex;
  font-family: var(--font-family);
  flex-direction: column;
  background: white;
  min-height: 100vh; /* Use min-height instead of height to accommodate content */
  padding-top: 10vh; /* This adds padding around the content */
  padding-left: 30px;
  padding-right: 30px;
}

/* This will style the title section */
.blog-title-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 5vh; /* Adjust top and bottom padding as needed */
  padding-bottom: 1vh;
}

/* This will style the title itself */
.blog-title-section h1 {
  font-size: 3rem; /* Adjust the font size as needed */
  font-weight: 400; /* OpenAI uses lighter font-weight */
  margin-bottom: 3vh; /* Space before the separator */
  color: var(--color-bg-darkGrey);

}

/* This will create the black line separator below the title */
.blog-title-section::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px; /* Height of the separator line */
  background-color: black;
  margin-top: 20vh; /* Space after the title */
}

/* This will style the content section */
.blog-content-section {
  font-size: 1rem; /* Adjust the font size for the content */
  line-height: 1.6; /* Adjust the line height for better readability */
  color: var(--color-bg-darkGrey);
}

.blog-author-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20vh;
    align-items: left;
    text-align: left;
    color: var(--color-bg-darkGrey);

}
.blog-content-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    align-items: center;
    text-align: left;
    padding: 5vh 45vh;
}
.blog-content-section p{
    line-height: 40px;
    font-size: 18px;
}
.blog-subtitle{
    margin-bottom:5vh;
}
.blog-subtitle h2,.blog-title-section h2{
    font-size: 23px;
    font-weight: 300;
}
.blog-content-paragraph {
    line-height: 40px;
    font-size: 18px;
    white-space: pre-line; /* <-- Add this property! */
    padding-bottom: 5vh;
}

.blog-content-title{
    display: flex;
    justify-content: left;
    width: 100%;
    padding-bottom: 5vh;
    font-weight: 600;
    font-size: 25px;
}

.blog-image{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 5vh;
}
@media (max-width: 1300px) {
.blog-content-section{
    padding: 5vh 35vh;
}
}
@media (max-width: 1200px) {
.blog-content-section{
    padding: 5vh 30vh;
}
}
@media (max-width: 1100px) {
.blog-content-section{
    padding: 5vh 25vh;
}
}
@media (max-width: 1000px) {
.blog-content-section{
    padding: 5vh 20vh;
}
}
@media (max-width: 900px) {
.blog-content-section{
    padding: 5vh 15vh;
}
}
@media (max-width: 800px) {
.blog-content-section{
    padding: 5vh 10vh;
}
}
@media (max-width: 480px) {
.blog-content-section{
    padding: 10px;
}
}