.pricing-container {
  min-height: 90vh;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  text-align: center;
  width: 100%;
  font-family: var(--font-family);
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  background-color: #F0E1FF;

}

.checkout-main{
 min-height: 90vh;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  text-align: center;
  width: 100%;
  font-family: var(--font-family);
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  background-color: #F0E1FF;
}
.top-pricing-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 165px;
    justify-content: space-between;

}
.top-pricing-intro-text{
    display: flex;
    width: 36%;
    height: 60%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.top-pricing-intro-text h1{
    font-size: 30px;
    font-weight: 800;
}
.pricing-type-main{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}
.pricing-type{
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
}
.pricing-type h3{
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;

}
.pricing-type.active {
   border-left: 2px solid #e2c6ff;
   border-right: 2px solid #e2c6ff;
   border-top: 2px solid #e2c6ff;
   background-color: #F0E1FF;
}
.pricing-card-container-main{
    display: flex;
    width: 100%;
    justify-content: center;
     background-color: #F0E1FF;
     border-top: 1px solid #e2c6ff;
}
.all-3-cards {
  display: flex;
  width: 78%; /* Takes up full width */
  justify-content: space-around; /* Distributes the features evenly */
  font-family: var(--font-family);
  height: 55vh;
  margin-top: 40px;
}


.Starter, .Pro , .Premium {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 28%;
  min-width: 28%;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  border: 2px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.Starter{
 background-image: linear-gradient(to top, #ffffff, #e2dcf5, #c6bae9, #ad97dc, #9575cd);
}
.Pro{
  background-image: linear-gradient(to top, #ffffff, #f1e6f9, #eecbeb, #f1aed3, #f48fb1);
}

.Premium{
  background-image: linear-gradient(to top, #ffffff, #ffe8ff, #ffcbdc, #ffb79a, #ffb74d);
}

.Pro:hover,
.Starter:hover,
.Premium:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.plan-details{
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
}

.plan-title{
    display: flex;
    flex-direction: column;
    height: 15%;
    margin-top: 10px;
}
.plan-price{
    display: flex;
    flex-direction: column;
    height: 10%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.Starter h2, .Pro h2, .Premium h2{
    color: #000;
    font-size: 30px;
    font-weight: 900;
    line-height: 110%;
    outline-color: #000;
}

.Starter h3,
.Pro h3,
.Premium h3 {
  font-size: 20px;
}
.Starter h5,.Pro h5, .Premium h5{
font-size: 16px;
text-align: left;
margin-bottom: 10px;
}

.Starter h4,
.Pro h4,
.Premium h4 {
  font-size: 12px;
  line-height: 30px;
  text-align: left;
  padding-bottom: 10px;
}
.subscribe-container{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.subscribe-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: #FF4820;  /* or any light orange shade you want, e.g., #FFCC99 */
    border: none;                  /* remove default border */
    border-radius: 15px;           /* rounded corners */
    height: 50px;
    color: white;                  /* white text color */
    font-size: 16px;               /* adjust if needed */
    cursor: pointer;               /* make it obvious it's clickable */
    transition: 0.3s ease;         /* smooth transition for hover effect */
    width: 70%;
    margin-bottom: 10px;
 box-shadow: inset 0 0 10px 0px rgba(0, 0, 0, 0.2);
}
.enterprise-container{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.enterprise-cards{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80%;
    justify-content: space-around;
}
.enterprise-helix-card,.enterprise-helix-card-custom{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 40%;
    padding: 15px;
     border: 2px solid black;
     border-radius: 10px;

}
.enterprise-helix-card{
  background-image: linear-gradient(to top, #ffffff, #f1e6f9, #eecbeb, #f1aed3, #f48fb1);

}
.enterprise-helix-card-custom{
  background-image: linear-gradient(to top, #ffffff, #ffe8ff, #ffcbdc, #ffb79a, #ffb74d);
}
.enterprise-plan-details{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60vh;
    justify-content: center;
    align-items: center;
}

.enterprise-helix-custom-title, .enterprise-helix-custom-title h2{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    font-size: 30px;
    font-weight: 800;
}

.enterprise-bullet-point{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    height: 75%;
    line-height: 30px;
    justify-content: center;
}
.enterprise-bullet-point ul, ul {
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside; /* To ensure bullets are within the container */
}
.enterprise-pricing-text{
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 15%;
         align-items: center;
     }
.enterprise-pricing-text-outline p,.enterprise-pricing-text-outline{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    background-color: #FF4820;  /* or any light orange shade you want, e.g., #FFCC99 */
    border-radius: 20px;
    height: 100%;
    color: white;
    text-align: center;
}

@media (max-width: 1440px){
.enterprise-pricing-text-outline p,.enterprise-pricing-text-outline{
width: 80%;
}

}
@media (max-width: 1350px) {
    .all-3-cards{
    width: 90%;
    height: 70vh;
    }
}
@media (max-width: 1280px) {
    .all-3-cards{
    width: 85%;
    }
    .enterprise-cards{
    height: 60vh;
    }
    .enterprise-plan-details{

    padding-top: 50px;
    }
    .enterprise-pricing-text-outline p,.enterprise-pricing-text-outline{
    width: 90%;
    }
}
@media (max-width: 1260px) {
    .all-3-cards{
    width: 80%;
    max-height: 80%;
    min-height: 80%;
    }
}
@media (max-width: 1000px) {
    .pricing-container{
        height: 100%;
    }
    .enterprise-plan-details{
    height: 100%;
    }
    .enterprise-cards{
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    }
    .enterprise-helix-card,.enterprise-helix-card-custom{
        margin-bottom: 50px;
        width: 60%;
        height: 60vh;
    }
    .enterprise-bullet-point{
        line-height: 50px;
    }
    .all-3-cards{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-right: 30%;
        padding-left: 30%;
    }
    .Starter,.Pro, .Premium{
        margin-bottom: 80px;
        height: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 600px;
    }
    .plan-details{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }

    .plan-title{
        display: flex;
        flex-direction: column;
        height: 120px;
        margin-top: 10px;
    }
    .plan-price{
        display: flex;
        flex-direction: column;
        height: 25px;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .Starter h2, .Pro h2, .Premium h2{
        color: #000;
        font-size: 50px;
        font-weight: 900;
        line-height: 110%;
        outline-color: #000;
    }

    .Starter h3,
    .Pro h3,
    .Premium h3 {
      font-size: 34px;
    }
    .Starter h5,.Pro h5, .Premium h5{
        font-size: 28px;
        text-align: left;
        margin-bottom: 20px;
    }

    .Starter h4,
    .Pro h4,
    .Premium h4 {
      font-size: 18px;
      text-align: left;
    }
    .subscribe-button {
        margin-bottom: 20px;
        height: 60px;
        width: 50%;
        align-items: center;
        justify-content: center;
    }
    .pricing-type{
width: 25%;
}
}
@media (max-width: 850px){
.enterprise-bullet-point{
        line-height: 40px;
    }

}
@media (max-width: 780px){
.top-pricing-container{
height: 250px;
}
.enterprise-bullet-point{
        line-height: 30px;
    }
}
@media (max-width: 700px) {
.all-3-cards{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-right: 20%;
        padding-left: 20%;
    }

    .enterprise-helix-card,.enterprise-helix-card-custom{
        margin-bottom: 50px;
        width: 70%;
        height: 80vh;
    }
    .enterprise-bullet-point{
        line-height: 50px;
    }
.pricing-type{
width: 30%;
}

}
@media (max-width: 600px) {
.enterprise-helix-card,.enterprise-helix-card-custom{
        margin-bottom: 50px;
        width: 85%;
        height: 70vh;
    }

}
@media (max-width: 550px) {
.top-pricing-container{
    height: 250px;
}
.enterprise-pricing-text-outline p,.enterprise-pricing-text-outline{
    width: 100%;
font-size: 14px;
}
.enterprise-helix-card,.enterprise-helix-card-custom{
        margin-bottom: 50px;
        width: 80%;
        height: 70vh;
    }
    .enterprise-bullet-point{
        line-height: 40px;
    }
.top-pricing-intro-text{
    width: 60%;
}
.pricing-type{
width: 40%;
}
.all-3-cards{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-right: 15%;
        padding-left: 15%;
    }


}
@media (max-width: 400px) {
.all-3-cards{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: auto;
        padding-top: 60px;
        padding-right: 10%;
        padding-left: 10%;
    }

.enterprise-helix-card,.enterprise-helix-card-custom{
        margin-bottom: 50px;
        width: 90%;
        height: 70vh;
    }
    .enterprise-bullet-point{
        line-height: 40px;
    }
}
.success-container,.cancel-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  overflow: hidden;
  background-image: radial-gradient(circle, #ffffff, #f5f1ff, #ece4ff, #e4d5ff, #dec7ff, #e8c1f8, #f1baf0, #fab4e5, #ffbbd4, #ffc4c9, #ffd0c6, #fbdbca);
}
.cancel-container{
    background-image: linear-gradient(to top, #ffffff, #fcf6fc, #ffebf1, #ffe2e0, #fbdbca);
}
.white-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 50%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #000;

}
.success-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-family: var(--font-family);
    width:100%;
}
.features-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.success-text h1, .success-text p{
    padding-bottom: 20px;
    text-align: center;
}
.success-text p{
    padding-right: 10%;
    padding-left: 10%;
}
.features-button, .cancel-button{
  padding: 12px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}







