.benefits-section {
  background-color: white;
  margin-bottom: 0;
  width:100%;
  border-radius-bottom:12px;

}

.benefits-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 6rem 2rem;
}
.benefits-wrapper {
  position: relative;
  width: 100%;
}

.benefits-purple-continue {
  background-color: var(--color-bg-offWhite);
  position: absolute;
  right: 50px;
  top: 0;  /* Changed from -150px to 0 to align with top of benefits section */
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  height: 300px;
  width: 330px;
  z-index: 1;
  display:flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}
.helix-simple{
    width: 80%;
    height: 80%;
    overflow: hidden;
}
.benefits-header {
  margin-bottom: 4rem;
  position: relative;
  z-index: 20;
}
.transparent-bg{
height: 100%;
width: 100%;
background-color: rgba(255, 255, 255, 0.4);

}
.benefits-header h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #333;
  margin-bottom: 1rem;
  padding-left: 1%;
}

.benefits-header h2 {
    font-size: 3.5rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 1.5rem;
    margin-left: 0;
    padding-left: 0;
    line-height: 1.2;
    text-align: left;  /* Replace align-items: left with this */
}

.benefits-header p {
  font-size: 1.25rem;
  color: #666;
  max-width: 800px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.benefit-icon {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 0.5rem;
}

.benefit-item h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
}

.benefit-item p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
}
@media (max-width: 1250px){
.benefits-container{
        max-width: 1200px;
    margin: 0 auto;
    padding: 6rem 2rem;
    background-color: rgba(255, 255, 255, 0.4);
    position: relative;
    z-index: 20;
}


}
@media (max-width: 1024px) {
  .benefits-header h2 {
    font-size: 3rem;
  }

  .benefits-grid {
    gap: 2rem;
  }
}

@media (max-width: 768px) {


  .benefits-header h2 {
    font-size: 2.5rem;
  }

  .benefits-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .benefit-item {
    text-align: left;
  }
}