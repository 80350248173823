/* AI Solutions Page Styles */
:root {
  --color-bg-yellow: #f0dd5f;
  --color-bg-green: #156e12;
  --color-bg-black: #071a14;
  --color-bg-offWhite: #e9eae0;
  --color-bg-darkGrey: #0f2922;

}

.ai-solutions-page {
  font-family: var(--font-family);
  color: var(--color-bg-black);
  background-color: var(--color-bg-offWhite);
  position: relative;
  overflow-x: hidden;
}

/* Network Background - Keep your existing animation */
.network-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.08;
  /* Your existing network animation goes here */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Section Styling */
section {
  padding: 5rem 0;
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-bg-black);
  margin-bottom: 1rem;
}

.underline {
  height: 4px;
  width: 80px;
  background-color: var(--color-bg-yellow);
  margin: 0 auto;
}

/* AI Solutions Banner Section */
.aiSolutionsBanner {
  background-color: var(--color-bg-black);
  color: white;
  min-height: 90vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.aiSolutionsBanner::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-bg-yellow);
}

.aiSolutionsBanner-wrapper {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 7rem;
}

.aiSolutionsBanner-content {
  flex: 1;
  padding-right: 2rem;
  max-width: 600px;
}

.aiSolutionsBanner h1 {
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
}

.title-impact {
  font-size: 5.5rem;
  font-weight: 900;
  color: var(--color-bg-yellow);
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.title-focus {
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -1px;
}

.aiSolutionsBanner-divider {
  width: 80px;
  height: 4px;
  background-color: var(--color-bg-green);
  margin: 2rem 0;
}

.aiSolutionsBanner-tagline {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  opacity: 0.9;
}

.aiSolutionsBanner-graphic {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.code-graphic {
  width: 100%;
  max-width: 500px;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-left: 3px solid var(--color-bg-green);
  padding-left: 2rem;
}

.code-line {
  height: 22px;
  background-color: rgba(240, 221, 95, 0.1);
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  animation: typing 3s infinite;
}

.code-line:nth-child(2) {
  width: 85%;
  animation-delay: 0.2s;
}

.code-line:nth-child(3) {
  width: 95%;
  animation-delay: 0.4s;
}

.code-line:nth-child(4) {
  width: 70%;
  animation-delay: 0.6s;
}

.code-line:nth-child(5) {
  width: 90%;
  animation-delay: 0.8s;
}
.code-line:nth-child(7) {
  width: 65%;
  animation-delay: 0.8s;
}
.code-line:nth-child(8) {
  width: 95%;
  animation-delay: 0.8s;
}
@keyframes typing {
  0%, 100% {
    background-color: rgba(240, 221, 95, 0.1);
  }
  50% {
    background-color: rgba(240, 221, 95, 0.2);
  }
}

.cta-button {
  background-color: var(--color-bg-green);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  background-color: #1a8417;
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

/* AI Solutions Approach Section */
.aiSolutionsApproach {
  background-color: var(--color-bg-offWhite);
  position: relative;
  padding: 0rem 0;
  overflow: hidden;
}

.aiSolutionsApproach::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 40%;
  height: 100%;
  background-color: var(--color-bg-darkGrey);
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
  z-index: 1;
}

.aiSolutionsApproach-header {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  z-index: 2;
  width: 100vw;
}

.aiSolutionsApproach-header h2 {
     font-size: 3rem;
    font-weight: 800;
    color: var(--color-bg-black);
    color: var(--color-bg-green);
    margin-bottom: 1rem;
    background-color: var(--color-bg-offWhite);
    border-radius: 15px;
    padding: 3rem 3rem;
}

.aiSolutionsApproach-underline {
  height: 4px;
  width: 80px;
  background-color: var(--color-bg-yellow);
}

.aiSolutionsApproach-content {
  display: flex;
  position: relative;
  z-index: 2;
}

.aiSolutionsApproach-text {
  flex: 1;
  padding-right: 4rem;
}

.aiSolutionsApproach-intro {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 500;
  margin-bottom: 3rem;
  color: var(--color-bg-darkGrey);
  border-left: 3px solid var(--color-bg-green);
  padding-left: 1.5rem;
}

.aiSolutionsApproach-column {
  margin-bottom: 3rem;
}

.aiSolutionsApproach-column h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--color-bg-black);
}

.aiSolutionsApproach-column p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #333;
}

.aiSolutionsApproach-stat {
  flex: 0 0 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
}

.stat-highlight {
  font-size: 6rem;
  font-weight: 800;
  line-height: 1;
  color: var(--color-bg-yellow);
  margin-bottom: 1.5rem;
}

.aiSolutionsApproach-stat p {
  font-size: 1.3rem;
  line-height: 1.5;
}

/* AI Capabilities Section - UPDATED */
.aiSolutionsCapabilities {
  background-color: var(--color-bg-black);
  color: white;
  position: relative;
  padding: 8rem 0;
}

.aiSolutionsCapabilities::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 6px;
  background-color: var(--color-bg-green);
}

.aiSolutionsCapabilities-header {
  text-align: center;
  margin-bottom: 5rem;
}

.aiSolutionsCapabilities-header h2 {
  font-size: 3rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1rem;
}

.aiSolutionsCapabilities-underline {
  height: 4px;
  width: 80px;
  background-color: var(--color-bg-yellow);
  margin: 0 auto;
}

/* NEW GRID LAYOUT FOR CAPABILITIES */
.capabilities-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  max-width: 1100px;
  margin: 0 auto;
}

.capability-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-left: 4px solid var(--color-bg-yellow);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.capability-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
}

.capability-card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: var(--color-bg-green);
  opacity: 0.1;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.capability-icon {
  margin-bottom: 1.5rem;
}

.icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-bg-green);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.icon-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, var(--color-bg-yellow) 0%, var(--color-bg-green) 70%);
  opacity: 0.3;
}

.capability-content h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.capability-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  opacity: 0.9;
}

.aiSolutionsCapabilities-cta {
  text-align: center;
  margin-top: 4rem;
}

.secondary-button {
  background-color: transparent;
  color: var(--color-bg-yellow);
  border: 2px solid var(--color-bg-yellow);
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.secondary-button:hover {
  background-color: var(--color-bg-yellow);
  color: var(--color-bg-black);
}

/* Process Section */
.process-section {
  background-color: var(--color-bg-offWhite);
  position: relative;
}

.process-timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.process-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 24px;
  height: 100%;
  width: 3px;
  background-color: var(--color-bg-green);
}

.process-step {
  position: relative;
  padding-bottom: 3rem;
  padding-left: 70px;
}

.step-number {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: var(--color-bg-green);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 700;
  z-index: 1;
}

.step-content {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.step-content h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-bg-darkGrey);
}

.step-content p {
  line-height: 1.7;
  color: #555;
}

/* Contact Section */
.contact-section {
  background-color: white;
  position: relative;
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, rgba(240, 221, 95, 0.05) 25%, transparent 25%, transparent 50%, rgba(240, 221, 95, 0.05) 50%, rgba(240, 221, 95, 0.05) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  opacity: 0.3;
}

.contact-flex {
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
  z-index: 1;
}

.contact-content {
  flex: 1;
}

.contact-content h2 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: var(--color-bg-darkGrey);
}

.contact-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.contact-benefits {
  list-style: none;
  padding: 0;
}

.contact-benefits li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.05rem;
}

.contact-benefits li::before {
  content: '✓';
  position: absolute;
  left: 0;
  top: 0;
  color: var(--color-bg-green);
  font-weight: bold;
}

.contact-form-container {
  flex: 1;
  background-color: var(--color-bg-offWhite);
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--color-bg-green);
  box-shadow: 0 0 0 3px rgba(21, 110, 18, 0.2);
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button {
  align-self: flex-start;
}

/* Responsive Styling */
@media (max-width: 1200px) {
  .aiSolutionsBanner h1 {
    font-size: 4rem;
  }

  .title-impact {
    font-size: 4.5rem;
  }

  .title-focus {
    font-size: 2.8rem;
  }

  .contact-flex {
    gap: 2rem;
  }
}
/* Add these styles to your AISolutions.css file */

/* Form Notification Styles */
.form-notification {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 15px;
  animation: slideDown 0.3s ease-out;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.success-notification {
  background-color: rgba(21, 110, 18, 0.1);
  border-left: 4px solid var(--color-bg-green);
}

.error-notification {
  background-color: rgba(220, 53, 34, 0.1);
  border-left: 4px solid #dc3545;
}

.notification-icon {
  font-size: 1.4rem;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
}

.success-notification .notification-icon {
  background-color: var(--color-bg-green);
  color: white;
}

.error-notification .notification-icon {
  background-color: #dc3545;
  color: white;
}

.notification-message {
  flex-grow: 1;
  font-size: 1rem;
  line-height: 1.5;
}

.success-notification .notification-message {
  color: var(--color-bg-green);
}

.error-notification .notification-message {
  color: #dc3545;
}

.notification-close {
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s;
}

.notification-close:hover {
  color: #333;
}

/* Button state for form submission */
.cta-button.submitting {
  background-color: #12520f;
  cursor: not-allowed;
  position: relative;
  overflow: hidden;
}

.cta-button.submitting::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loadingShimmer 1.5s infinite;
}

@keyframes loadingShimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 992px) {
  .aiSolutionsBanner-wrapper {
    flex-direction: column;
  }

  .aiSolutionsBanner-content {
    padding-right: 0;
    max-width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }

  .aiSolutionsBanner-divider {
    margin: 2rem auto;
  }

  .aiSolutionsApproach-content {
    flex-direction: column;
  }

  .aiSolutionsApproach-text {
    padding-right: 0;
    margin-bottom: 3rem;
  }

  .aiSolutionsApproach-stat {
    max-width: 100%;
    flex: none;
  }

  .contact-flex {
    flex-direction: column;
  }

  .contact-content,
  .contact-form-container {
    width: 100%;
  }

  .capabilities-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  section {
    padding: 5rem 0;
  }

  .container {
    padding: 0 1.5rem;
  }

  .aiSolutionsBanner h1 {
    font-size: 3rem;
  }

  .title-impact {
    font-size: 3.5rem;
  }

  .title-focus {
    font-size: 2.2rem;
  }

  .aiSolutionsBanner-tagline {
    font-size: 1.2rem;
  }

  .aiSolutionsApproach-header h2,
  .aiSolutionsCapabilities-header h2 {
    font-size: 2.5rem;
  }

  .aiSolutionsApproach::before {
    width: 100%;
    height: 40%;
    top: auto;
    bottom: 0;
    clip-path: none;
  }

  .process-timeline::before {
    left: 20px;
  }

  .process-step {
    padding-left: 60px;
  }

  .step-number {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .aiSolutionsBanner {
    min-height: auto;
    padding: 6rem 0;
  }

  .aiSolutionsBanner h1 {
    font-size: 2.5rem;
  }

  .title-impact {
    font-size: 3rem;
  }

  .title-focus {
    font-size: 1.8rem;
  }

  .aiSolutionsBanner-tagline {
    font-size: 1rem;
  }

  .code-graphic {
    height: 300px;
  }

  .aiSolutionsApproach-header h2,
  .aiSolutionsCapabilities-header h2,
  .section-header h2 {
    font-size: 2rem;
  }

  .aiSolutionsApproach-intro {
    font-size: 1.2rem;
  }

  .aiSolutionsApproach-column h3 {
    font-size: 1.5rem;
  }

  .contact-form-container {
    padding: 2rem;
  }

  .capability-content h3 {
    font-size: 1.6rem;
  }

  .stat-highlight {
    font-size: 4.5rem;
  }
}