/****************************************************
 * Overall Layout 
 ****************************************************/
.top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px 50px; /* Adjust as needed */
  height: 10vh;
}

/****************************************************
 * Gradient Text (for "Helix Labs")
 * Keep the original gradient look
 ****************************************************/
.title {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 55px;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
  margin: 0 20px;
}

/*
  .gradient__text must set text to transparent so
  the gradient background shows through
*/
.gradient__text {
  background: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/****************************************************
 * Desktop Navigation (>= 1280px)
 ****************************************************/
.desktop-nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Desktop nav links */
.desktop-nav a, .desktop-nav span {
  text-decoration: none;
  color: #333;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 23px;
  transition: color 0.2s;
}

.desktop-nav a:hover,
.desktop-nav span:hover {
  color: #156e12; /* Slight hover effect */
  cursor: pointer;

}

/****************************************************
 * "What We Do" Dropdown (Desktop)
 ****************************************************/
.what-we-do-dropdown {
  position: relative;
}

.what-we-do-dropdown-content {
  position: absolute;
  top: 2rem;
  left: 0;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  width: 220px;
  padding: 0.5rem 0;
  z-index: 10;
}

/* Links inside the "What We Do" dropdown */
.what-we-do-dropdown-content a {
  display: block;
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.what-we-do-dropdown-content a:hover {
  background-color: #efefef;
}

/****************************************************
 * Green Box for "Get Started" (Desktop)
 ****************************************************/
.get-started-container {
  background-color: #156e12;
  border-radius: 20px;
  padding: 0.5rem 1rem;
}

.get-started-container span {
  color: white;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}

/****************************************************
 * Mobile Navigation (< 1280px)
 ****************************************************/
.mobile-nav {
  display: none; /* Hidden on large screens */
}

/****************************************************
 * Hamburger Menu Styling
 ****************************************************/
.hamburger-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

/* The hamburger icon */
.hamburger-icon {
  width: 30px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

/* The hamburger icon bars */
.hamburger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #333;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

/* Position the 3 bars of the hamburger */
.hamburger-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-icon span:nth-child(2) {
  top: 8px;
}

.hamburger-icon span:nth-child(3) {
  top: 16px;
}

/* Animation for the hamburger icon when open */
.hamburger-icon.open span:nth-child(1) {
  top: 8px;
  transform: rotate(135deg);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger-icon.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-135deg);
}

/* Mobile dropdown menu */
.mobile-nav-dropdown {
  position: absolute;
  top: 10vh;
  right: 50px;
  background-color: #fff;
  border-radius: 8px;
  width: 50vw;
  max-width: 300px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  z-index: 50;
}

.mobile-nav-dropdown a,
.mobile-nav-dropdown span {
  margin: 0.7rem 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
  cursor: pointer;
}

.mobile-nav-dropdown a:hover,
.mobile-nav-dropdown span:hover {
  color: #156e12;
}

/****************************************************
 * Nested "What We Do" in Mobile
 ****************************************************/
.what-we-do-dropdown-mobile {
  margin: 0.5rem 0;
  border-radius: 4px;
}

.what-we-do-dropdown-mobile span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0;
  transition: all 0.2s ease;
}

.what-we-do-dropdown-mobile span.active {
  color: #156e12;
  font-weight: 700;
}

.mobile-submenu {
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border-left: 2px solid #eaeaea;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.mobile-submenu a {
  margin: 0.3rem 0;
  padding-left: 0.5rem;
  font-size: 16px;
}

/* Mobile Get Started Button */
.mobile-get-started {
  background-color: #156e12;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  text-align: center;
}

.mobile-get-started span {
  color: white;
  margin: 0;
}

/****************************************************
 * Media Queries
 ****************************************************/
@media (max-width: 1279px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .top-bar {
    padding: 20px 30px;
  }

  .title {
    font-size: 40px;
  }

  .mobile-nav-dropdown {
    right: 30px;
    width: 70vw;
  }
}