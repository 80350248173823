:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f0f3f5;
  --text-color: #2c3e50;
  --highlight-color: #e74c3c;
}

.graph-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--background-color);
  overflow: hidden;
}

.details-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 280px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: var(--text-color);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: all 0.3s ease;
}

.details-panel h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.details-panel button {
  margin-top: 15px;
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-panel button:hover {
  background-color: var(--highlight-color);
}

.control-panel, .search-bar {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.search-bar {
  top: 80px;
}

.control-panel select, .search-bar input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}