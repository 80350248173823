/* TempChat page specific variables */
.temp-chat-page {
  --temp-chat-yellow: #f0dd5f;
  --temp-chat-green: #156e12;
  --temp-chat-black: #071a14;
  --temp-chat-offWhite: #e9eae0;
  --temp-chat-darkGrey: #0f2922;
}

/* Main container for the construction page */
.temp-chat-construction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh; /* Reduced to allow room for footer */
  background-color: var(--temp-chat-black);
  padding: 20px;
}

/* Content box styling */
.temp-chat-construction-content {
  background-color: var(--temp-chat-offWhite);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
}

/* Heading styling */
.temp-chat-heading {
  color: var(--temp-chat-green);
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

/* Paragraph styling */
.temp-chat-paragraph {
  color: var(--temp-chat-darkGrey);
  font-size: 18px;
  margin-bottom: 30px;
  font-family: Arial, sans-serif;
}

/* User link section styling */
.temp-chat-user-link {
  background-color: var(--temp-chat-yellow);
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
}

.temp-chat-user-link .temp-chat-paragraph {
  margin-bottom: 0;
}

/* Link styling */
.temp-chat-link {
  color: var(--temp-chat-green);
  font-weight: bold;
  text-decoration: none;
}

.temp-chat-link:hover {
  text-decoration: underline;
}