* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;

}

body {
    background: var(--color-bg);
}

a {
    text-decoration: none;
    color: unset;
}

.landing-gradient {
 background-image: white;
 }


  /* 2nd safari 5.1+,chrome 10+ *//* opera 11.10+ */  /* ie 10+ */ /* global 92%+ browsers support */
.gradient__bg {
  background-image: linear-gradient(to bottom, #040c18, #051021, #04142b, #011735, #00193f);
}
.underline-text {
  text-decoration: underline;
}

.gradient__text{
  background:var(--gradient-text);
  -webkit-background-clip: text;
  -background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
    padding:4rem 6rem;
}


.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
.app-content {
  display: flex;   /* Use flexbox to lay out the children horizontally */
  height: 100%;
}
/* Existing styles... */

/* Existing styles... */

.content-wrapper {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
}

.right-content {
  flex: 1; /* This will allow the right content to take up the remaining space if needed */
  display: flex;
  flex-direction: column;
}
.UserInput {
    margin-top: auto;
}
.App, .gradient_bg, .content-wrapper {
    height: 100%;
}
.header-userinput-container {
  overflow-y: auto; /* Enables vertical scrolling */

}
body {
  font-family: var(--font-family);
  background: var(--color-bg);
  color: var(--color-text);
}

